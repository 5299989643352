import { Serializer } from "../models/helpers/serializer";
import { Trip } from "../models/trip/trip";
import { TripSearch } from "../models/trip/trip-search";

export class TripSearchToConfirmSerializer implements Serializer {

    public fromJson(json: any): any {
        const tripSearch:TripSearch = { currentPage:json.currentPage, pageCount:json.pageCount };
        const trips: Trip[] = [];                   

        json.Trips.forEach(element => {
            const item: Trip = Object.assign(new Trip(), element);
            trips.push(item);
        });

        tripSearch.trips = trips;
        return tripSearch;
    }

    public fromJsonCollection(json: any): any[] {
        throw new Error("Method not implemented.");
    }
}