import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ICompanyConfig } from '../models/config/company-config';
import { ConfigService } from '../services/config.service';
import { LocationHelper } from './location.helper';

@Injectable({
    providedIn: 'root'
})
export class SeoHelper {
    
    public constructor(
        private titleService: Title,
        private metaService: Meta,
        @Inject(DOCUMENT) private document: Document,
        private configService: ConfigService
    ) {
    }

    private get config(): ICompanyConfig {
        return this.configService.company;
    } 

    setCanonical = (url: string): void => {
        const element = this.document.querySelector('link[rel="canonical"]')
        element.setAttribute('href', url);
    }

    setTitle = (url: string): void => {
        if (this.config.idTenant === 1) {
            let title = this.resolveUtilUrl(url);
            this.titleService.setTitle(title);
        } else if (this.config.idTenant === 2) {
            let title = this.resolveRoyalUrl(url);
            this.titleService.setTitle(title);
        } else if (this.config.idTenant === 3) {
            let title = this.resolveSampaioUrl(url);
            this.titleService.setTitle(title);
        } else if (this.config.idTenant === 4) {
            let title = this.resolveRealRapidoUrl(url);
            this.titleService.setTitle(title);
        } else if (this.config.idTenant === 5) {
            let title = this.resolveGuanabaraUrl(url);
            this.titleService.setTitle(title);
        }
    }

    setMetadata = (url: string): void => {
        if (this.config.idTenant === 1) {
            let metadata = this.resolveUtilMetadata(url);
            this.metaService.updateTag({ name: 'description', content: metadata });
        } else if (this.config.idTenant === 2) {
            let metadata = this.resolveRoyalMetadata(url);
            this.metaService.updateTag({ name: 'description', content: metadata });
        } else if (this.config.idTenant === 3) {
            let metadata = this.resolveSampaioMetadata(url);
            this.metaService.updateTag({ name: 'description', content: metadata });
        } else if (this.config.idTenant === 4) {
            let metadata = this.resolveRealRapidoMetadata(url);
            this.metaService.updateTag({ name: 'description', content: metadata });
        } else if (this.config.idTenant === 5) {
            let metadata = this.resolveGuanabaraMetadata(url);
            this.metaService.updateTag({ name: 'description', content: metadata });
        }
    }

    removeParamsFromUrl = (url: string): string => {
        return url.includes('?')
            ? url.split('?')[0]
            : url;
    }

    resolveUtilMetadata = (url: string): string => {
        url = this.removeParamsFromUrl(url);
        let result = '';

        if (url.includes('checkout')) {
            result = 'Checkout';
        } else if (url.includes('institucional')) {
            result = 'Conheça a trajetória da Viação Util e veja tudo sobre as primeiras viagens de ônibus realizadas pela empresa entre as cidades do Rio de Janeiro e Petrópolis';
        } else if (url.includes('horarios-de-agencias')) {
            result = 'Confira o horário de funcionamento do guichê das cidades de todo o Brasil em que a Viação Util atende. Garanta a sua passagem online';
        } else if (url.includes('frota')) {
            if (url.split('/').length === 2) {
                result = `Saiba tudo sobre a linha ${LocationHelper.restoreLocationName(url.split('/')[1])} da Viação UTIL. Confira aqui a descrição do veículo com todos os detalhes. `;
            } else {
                result = 'Confira quais ônibus compõem nossa frota, bem como suas respectivas características. Veja qual melhor se adapta às suas necessidades e anseios. Viação UTIL';
            }
        } else if (url.includes('servicos')) {
            const parts = url.split('/');
            if (parts.length === 3) {
                if (url.includes('aluguel')) {
                    result = 'Saiba como é fácil alugar um Ônibus com a Viação UTIL para passeios, excursões, turismo e eventos. Entre e confira nossos modelos, cidades e disponibilidade';
                } else if (url.includes('fretamentos')) {
                    result = 'Passeios culturais, viagens turísticas, transporte dos funcionários de sua empresa e muito mais. O Fretamento de ônibus da viação útil pode te ajudar.';
                } else if (url.includes('fretamento')) {
                    result = 'Na Viação Util você encontra um serviço de fretamento de ônibus para viagens turísticas, passeios culturais e transporte de funcionários com segurança e rapidez';
                } else if (url.includes('conexao-galeao')) {
                    result = 'A UTIL tem ônibus direto de Juiz de Fora (MG) para o Aeroporto do Galeão, no Rio de Janeiro, com desembarque realizado no Terminal 1 e também no Terminal 2';
                } else {
                    result = 'O serviço de encomendas da Viação UTIL mudou. Agora você perceberá ainda mais agilidade e eficiência ao utilizar nosso serviço de entregas. Clique e confira!';
                }
            } else if (parts.length === 4) {
                if (url.includes('aluguel')) {
                    result = `Saiba como é fácil alugar um Ônibus em ${LocationHelper.restoreLocationName(url.split('/')[3])} com a Viação UTIL para passeios, excursões, turismo e eventos. Entre e confira nossos modelos, trajetos e disponibilidade.`;
                } else if (url.includes('fretamentos')) {
                    result = `Conheça todos os trajetos e opções de frete de ônibus em ${LocationHelper.restoreLocationName(url.split('/')[3])} com a Viação UTIL. Entre e confira nossas opções.`;
                }
            } else {
                result = 'A Viação UTIL procura sempre oferecer serviços relacionados a ônibus que facilitem sua vida. Confira nessa seção quais são e como nós podemos ajudá-lo. Acesse!';
            }
        } else if (url.includes('perguntas-frequentes')) {
            result = 'Perguntas Frequentes - Dúvidas sobre viajar de ônibus?';
        } else if (url.includes('onibus') && url.split('/').length === 3) {
            result = `Compre Passagens de Ônibus de ${LocationHelper.restoreLocationName(url.split('/')[2])} na Viação UTIL. Garanta Passagens com antecedência, segurança e conforto.`
        } else if (url.includes('onibus') && url.split('/').length === 4) {
            result = `Compre Passagens de Ônibus Online Com antecedência, segurança e conforto de ${LocationHelper.restoreLocationName(url.split('/')[2])}` +
                ` para ${LocationHelper.restoreLocationName(url.split('/')[3])} aqui na Viação UTIL. `;
        } else if (url.includes('faq')) {
            result = 'Se você ainda não encontrou o que estava procurando sobre a Gipssy, nesta página pode ser que você encontre. Caso não encontre, entre em contato conosco!';
        } else if (url.includes('privacidade')) {
            result = 'Conheça a política de privacidade da Viação Util e saiba sobre o armazenamento dos seus dados, o motivo deles serem coletados e mais informações';
        } else if (url.includes('termos')) {
            result = 'Aqui você conhece os termos e condições de uso dos serviços oferecidos pela Viação Util, além de ter informações de taxas, processos e muito mais';
        } else if (url.includes('faq')) {
            result = 'XXXX';
        } else {
            result = 'Compre passagens de ônibus da Viação UTIL. Garanta sua passagem online, com antecedência, segurança e conforto. Acesse o site e garanta já a sua!';
        }

        return result;
    }

    resolveRoyalMetadata = (url: string): string => {
        url = this.removeParamsFromUrl(url);
        let result = '';

        if (url.includes('checkout')) {
            result = 'Checkout';
        } else if (url.includes('survey')) {
            result = `Pesquisa de satisfação`;
        }
        else if (url.includes('institucional')) {
            result = 'Saiba tudo sobre a Royal Express. Entenda como garantir sua passagem online, com antecedência, segurança e conforto. Acesse o site e saiba mais!';
        } else if (url.includes('horarios-de-agencias')) {
            result = 'Confira o Horário de Funcionamento das nossas Agências na sua cidade. Garanta sua passagem online aqui na Royal Express';
        } else if (url.includes('frota')) {
            if (url.split('/').length === 2) {
                result = `Saiba tudo sobre a linha ${LocationHelper.restoreLocationName(url.split('/')[1])} da Royal Express. Confira aqui a descrição do veículo com todos os detalhes. `;
            } else {
                result = 'Confira quais ônibus compõem nossa frota, bem como suas respectivas características. Veja qual melhor se adapta às suas necessidades e anseios. Royal Express';
            }
        } else if (url.includes('servicos')) {
            const parts = url.split('/');
            if (parts.length === 3) {
                if (url.includes('aluguel')) {
                    result = 'Saiba como é fácil alugar um Ônibus com a Royal Express. Entre e confira nossos modelos, cidades e disponibilidade.';
                } else if (url.includes('fretamentos')) {
                    result = 'Passeios culturais, viagens turísticas, transporte dos funcionários de sua empresa e muito mais. O Fretamento de ônibus da Royal Express pode te ajudar.';
                } else {
                    result = 'O serviço de encomendas da Royal Express mudou. Agora você perceberá ainda mais agilidade e eficiência ao Royalizar nosso serviço de entregas';
                }
            } else if (parts.length === 4) {
                if (url.includes('aluguel')) {
                    result = `Saiba como é fácil alugar um Ônibus em ${LocationHelper.restoreLocationName(url.split('/')[3])} com a Royal Express. Entre e confira nossos modelos, trajetos e disponibilidade. `;
                } else if (url.includes('fretamentos')) {
                    result = `Conheça todos os trajetos e opções de frete de ônibus em ${LocationHelper.restoreLocationName(url.split('/')[3])} com a Royal Express. Entre e confira nossas opções.`;
                }
            } else {
                result = 'A Royal procura sempre oferecer serviços que facilitem sua vida. Confira nessa seção quais são e como nós podemos ajudá-lo. Qualquer dúvida, entre em contato.';
            }
        } else if (url.includes('perguntas-frequentes')) {
            result = 'Perguntas Frequentes - Dúvidas sobre viajar de ônibus?';
        } else if (url.includes('onibus') && url.split('/').length === 3) {
            result = `Expresso: ${LocationHelper.restoreLocationName(url.split('/')[2])}`
        } else if (url.includes('onibus') && url.split('/').length === 4) {
            result = `Expresso: ${LocationHelper.restoreLocationName(url.split('/')[2])}` +
                ` - ${LocationHelper.restoreLocationName(url.split('/')[3])}`;
        } else {
            result = 'Adquira o seu bilhete de expresso';
        }

        return result;
    }

    resolveUtilUrl = (url: string): string => {
        url = this.removeParamsFromUrl(url);
        let result = '';

        if (url.includes('checkout')) {
            result = 'Checkout';
        } else if (url.includes('institucional')) {
            result = 'Conheça tudo sobre a história da empresa';
        } else if (url.includes('horarios-de-agencias')) {
            result = 'Horário de funcionamento dos guichês das cidades';
        } else if (url.includes('frota')) {
            if (url.split('/').length === 2) {
                result = `${LocationHelper.restoreLocationName(url.split('/')[1])} Nossa Frota e o que podemos oferecer`;
            } else {
                result = 'Conheça a Nossa Frota e o que podemos oferecer';
            }
        } else if (url.includes('conclusion')) {
            result = 'Compra finalizada';
        } else if (url.includes('servicos')) {
            const parts = url.split('/');
            if (parts.length === 3) {
                if (url.includes('aluguel')) {
                    result = 'Aluguel de ônibus para passeios';
                } else if (url.includes('fretamentos')) {
                    result = 'Fretamento de Ônibus';
                } else if (url.includes('fretamento')) {
                    result = 'Serviço de fretamento de ônibus';
                } else if (url.includes('conexao-galeao')) {
                    result = 'Conexão direta com o aeroporto do Galeão';
                } else if (url.includes('encomendas')) {
                    result = 'Envie a sua encomenda com agilidade e segurança';
                } else {
                    result = 'Confira todos os serviços da empresa';
                }
            } else if (parts.length === 4) {
                if (url.includes('aluguel')) {
                    result = `Aluguel de ônibus para passeios em ${LocationHelper.restoreLocationName(url.split('/')[3])}`;
                } else if (url.includes('fretamentos')) {
                    result = `Fretamento de Ônibus em ${LocationHelper.restoreLocationName(url.split('/')[3])}`;
                }
            } else {
                result = 'Confira todos os serviços da empresa';
            }
        } else if (url.includes('perguntas-frequentes')) {
            result = 'Perguntas Frequentes - Dúvidas sobre viajar de ônibus?';
        } else if (url.includes('onibus') && url.split('/').length === 3) {
            result = `Passagem de Onibus de ${LocationHelper.restoreLocationName(url.split('/')[2])}`
        } else if (url.includes('onibus') && url.split('/').length === 4) {
            result = `Passagem de Onibus de ${LocationHelper.restoreLocationName(url.split('/')[2])}` +
                ` para ${LocationHelper.restoreLocationName(url.split('/')[3])}`;
        } else if (url.includes('faq')) {
            result = 'Faça uma viagem de ônibus segura e com conforto';
        } else if (url.includes('privacidade')) {
            result = 'Conheça a política de privacidade da empresa';
        } else if (url.includes('termos')) {
            result = 'Termos e condições de uso da empresa';
        } else {
            result = 'Compre a sua passagem de ônibus e aproveite';
        }

        return result + ' - Viacao UTIL';
    }

    resolveRoyalUrl = (url: string): string => {
        url = this.removeParamsFromUrl(url);
        let result = '';

        if (url.includes('checkout')) {
            result = 'Checkout';
        } else if (url.includes('survey')) {
            result = `Pesquisa de satisfação`;
        } else if (url.includes('institucional')) {
            result = 'Sobre a Empresa - Institucional';
        } else if (url.includes('horarios-de-agencias')) {
            result = 'Horário de Funcionamento de Agências';
        } else if (url.includes('frota')) {
            if (url.split('/').length === 2) {
                result = `${LocationHelper.restoreLocationName(url.split('/')[1])} Nossa Frota e o que podemos oferecer`;
            } else {
                result = 'Conheça a Nossa Frota e o que podemos oferecer';
            }
        } else if (url.includes('conclusion')) {
            result = 'Compra finalizada';
        } else if (url.includes('servicos')) {
            const parts = url.split('/');
            if (parts.length === 3) {
                if (url.includes('aluguel')) {
                    result = 'Aluguel de Ônibus';
                } else if (url.includes('fretamentos')) {
                    result = 'Fretamento de Ônibus';
                } else {
                    result = 'Serviço de Encomendas';
                }
            } else if (parts.length === 4) {
                if (url.includes('aluguel')) {
                    result = `Aluguel de Ônibus em ${LocationHelper.restoreLocationName(url.split('/')[3])}`;
                } else if (url.includes('fretamentos')) {
                    result = `Fretamento de Ônibus em ${LocationHelper.restoreLocationName(url.split('/')[3])}`;
                }
            } else {
                result = 'Conheça Todos os Serviços que a Royal oferece para você';
            }
        } else if (url.includes('perguntas-frequentes')) {
            result = 'Perguntas Frequentes - Dúvidas sobre viajar de ônibus?';
        } else if (url.includes('onibus') && url.split('/').length === 3) {
            result = `Expresso: ${LocationHelper.restoreLocationName(url.split('/')[2])}`
        } else if (url.includes('onibus') && url.split('/').length === 4) {
            result = `Expresso: ${LocationHelper.restoreLocationName(url.split('/')[2])}` +
                ` - ${LocationHelper.restoreLocationName(url.split('/')[3])}`;
        } else {
            result = 'Adquira o seu bilhete de expresso';
        }

        return result + ' | Royal Express';
    }

    resolveSampaioUrl = (url: string): string => {
        url = this.removeParamsFromUrl(url);
        let result = '';

        if (url.includes('checkout')) {
            result = 'Checkout';
        } else if (url.includes('institucional')) {
            result = 'Conheça tudo sobre a história da empresa';
        } else if (url.includes('horarios-de-agencias')) {
            result = 'Horário de Funcionamento de Agências';
        } else if (url.includes('frota')) {
            if (url.split('/').length === 2) {
                result = `${LocationHelper.restoreLocationName(url.split('/')[1])} Nossa Frota e o que podemos oferecer`;
            } else {
                result = 'Conheça a Nossa Frota e o que podemos oferecer';
            }
        } else if (url.includes('conclusion')) {
            result = 'Compra finalizada';
        } else if (url.includes('servicos')) {
            const parts = url.split('/');
            if (parts.length === 3) {
                if (url.includes('aluguel')) {
                    result = 'Aluguel de Ônibus';
                } else if (url.includes('fretamentos')) {
                    result = 'Fretamento de Ônibus';
                } else if (url.includes('fretamento')) {
                    result = 'Serviço de fretamento de ônibus';
                } else {
                    result = 'Serviço de Encomendas';
                }
            } else if (parts.length === 4) {
                if (url.includes('aluguel')) {
                    result = `Aluguel de Ônibus em ${LocationHelper.restoreLocationName(url.split('/')[3])}`;
                } else if (url.includes('fretamentos')) {
                    result = `Fretamento de Ônibus em ${LocationHelper.restoreLocationName(url.split('/')[3])}`;
                }
            } else {
                result = 'Veja os serviços oferecidos pela empresa';
            }
        } else if (url.includes('perguntas-frequentes')) {
            result = 'Perguntas Frequentes - Dúvidas sobre viajar de ônibus?';
        } else if (url.includes('onibus') && url.split('/').length === 3) {
            result = `Passagem de Onibus de ${LocationHelper.restoreLocationName(url.split('/')[2])}`
        } else if (url.includes('onibus') && url.split('/').length === 4) {
            result = `Passagem de Onibus de ${LocationHelper.restoreLocationName(url.split('/')[2])}` +
                ` para ${LocationHelper.restoreLocationName(url.split('/')[3])}`;
        } else if (url.includes('privacidade')) {
            result = 'Política de privacidade da empresa';
        } else if (url.includes('termos')) {
            result = 'Termos de serviços e de condições de uso';
        } else {
            result = 'Compre a sua passagem de onibus e aproveite';
        }

        return result + ' - Viação Sampaio';
    }

    resolveSampaioMetadata = (url: string): string => {
        url = this.removeParamsFromUrl(url);
        let result = '';

        if (url.includes('checkout')) {
            result = 'Checkout';
        } else if (url.includes('institucional')) {
            result = ' Conheça toda a história da Viação Sampaio, referência de empresa de ônibus no Brasil. Viaje com qualidade entre Rio-São Paulo e para o sul de Minas Gerais';
        } else if (url.includes('horarios-de-agencias')) {
            result = 'Confira o Horário de Funcionamento das nossas Agências na sua cidade. Garanta sua passagem online aqui na Viação Útil';
        } else if (url.includes('frota')) {
            if (url.split('/').length === 2) {
                result = `Saiba tudo sobre a linha ${LocationHelper.restoreLocationName(url.split('/')[1])} da Viação Sampaio. Confira aqui a descrição do veículo com todos os detalhes. `;
            } else {
                result = 'Confira quais ônibus compõem nossa frota, bem como suas respectivas características. Veja qual melhor se adapta às suas necessidades e anseios. Viação Sampaio';
            }
        } else if (url.includes('servicos')) {
            const parts = url.split('/');
            if (parts.length === 3) {
                if (url.includes('aluguel')) {
                    result = 'Saiba como é fácil alugar um Ônibus com a Viação Sampaio. Entre e confira nossos modelos, cidades e disponibilidade.';
                } else if (url.includes('encomendas')) {
                    result = 'O serviço de encomendas da Viação Sampaio mudou. Agora você perceberá ainda mais agilidade e eficiência ao utilizar nosso serviço de entregas. Veja os detalhes.';
                } else if (url.includes('fretamentos')) {
                    result = 'Passeios culturais, viagens turísticas, transporte dos funcionários de sua empresa e muito mais. O Fretamento de ônibus da viação útil pode te ajudar.';
                } else if (url.includes('fretamento')) {
                    result = 'O fretamento de ônibus da Viação Sampaio é ideal para passeios culturais, viagens turísticas e transporte de funcionários. Viaje com segurança e conforto';
                } else {
                    result = ' A Sampaio procura sempre oferecer serviços que facilitem sua vida. Confira nessa seção quais são e como nós podemos ajudá-lo. Qualquer dúvida, entre em contato.';
                }
            } else if (parts.length === 4) {
                if (url.includes('aluguel')) {
                    result = `Saiba como é fácil alugar um Ônibus em ${LocationHelper.restoreLocationName(url.split('/')[3])} com a Viação Sampaio. Entre e confira nossos modelos, trajetos e disponibilidade. `;
                } else if (url.includes('fretamentos')) {
                    result = `Conheça todos os trajetos e opções de frete de ônibus em ${LocationHelper.restoreLocationName(url.split('/')[3])} com a Viação Sampaio. Entre e confira nossas opções.`;
                }
            } else {
                result = 'A Sampaio procura sempre oferecer serviços que facilitem sua vida. Confira nessa seção quais são e como nós podemos ajudá-lo. Qualquer dúvida, entre em contato.';
            }
        } else if (url.includes('perguntas-frequentes')) {
            result = 'Perguntas Frequentes - Dúvidas sobre viajar de ônibus?';
        } else if (url.includes('onibus') && url.split('/').length === 3) {
            result = `Compre Passagens de Ônibus de ${LocationHelper.restoreLocationName(url.split('/')[2])} na Viação Sampaio. Garanta Passagens com antecedência, segurança e conforto.`
        } else if (url.includes('onibus') && url.split('/').length === 4) {
            result = `Compre Passagens de Ônibus Online Com antecedência, segurança e conforto de ${LocationHelper.restoreLocationName(url.split('/')[2])}` +
                ` para ${LocationHelper.restoreLocationName(url.split('/')[3])} aqui na Viação Sampaio. `;
        } else if (url.includes('privacidade')) {
            result = 'Nesta página da Viação Sampaio você conhece quais dados o site coleta, qual é a aplicabilidade da política de privacidade, com quem compartilhamos e muito mais';
        } else {
            result = 'Compre passagens de ônibus da Viação Sampaio. Garanta sua passagem online, com antecedência, segurança e conforto. Acesse o site e garanta já a sua!';
        }

        return result;
    }
    
    resolveRealRapidoUrl = (url: string): string => {
        url = this.removeParamsFromUrl(url);
        let result = '';

        if (url.includes('institucional')) {
            result = 'Toda a história e diferencial da empresa';
        } else if (url.includes('privacidade')) {
            result = 'Política de privacidade';
        } else if (url.includes('termos')) {
            result = 'Termos e condições de uso dos serviços';
        } else if (url.includes('faq')) {
            result = 'Perguntas frequentes de como viajar de ônibus';
        } else if (url.includes('horarios-de-agencias')) {
            result = 'Horários e endereços do guichê da sua cidade';
        } else if (url.includes('servicos/encomendas')) {
            result = 'Entrega de encomendas fracionadas';
        } else if (url.includes('servicos/aluguel-de-onibus')) {
            result = 'Aluguel de ônibus para passeio';
        } else if (url.includes('/onibus')) {
            result = 'Cidades de destino de viagem de ônibus';
        } else {
            result = 'Viaje de ônibus no Brasil com o melhor preço';
        }

        return result + ' - Real Expresso';
    }

    resolveRealRapidoMetadata = (url: string): string => {
        url = this.removeParamsFromUrl(url);
        let result = '';

        if (url.includes('institucional')) {
            result = 'Veja toda a história da Real Expresso e Rápido Federal e conheça os tipos de ônibus que a empresa disponibiliza, além de oferecer preços competitivos';
        } else if (url.includes('privacidade')) {
            result = 'Confira a política de privacidade do site da Real Expresso. O conteúdo está dividido em tópicos como política de cookies, transferência de dados e mais. Veja.';
        } else if (url.includes('termos')) {
            result = 'Confira os termos e condições de uso dos serviços do site da Real Expresso. Confira os direitos e deveres da empresa de ônibus. Veja todos os detalhes.';
        } else if (url.includes('faq')) {
            result = 'Nessa página você encontra informações sobre seguro viagem, regras de viagem, bilhetes, bagagem, transporte de animais, remarcações e gratuidades.';
        } else if (url.includes('horarios-de-agencias')) {
            result = 'Veja o endereço e os horários de atendimento dos guichês da Real Expresso. Veja as cidades brasileiras que a empresa atua. Clique e confira!';
        } else if (url.includes('servicos/encomendas')) {
            result = 'Aqui você encontra todas as informações sobre como enviar as suas encomendas em um ônibus da Real Expresso. É rápido, seguro e barato. Clique e confira.';
        } else if (url.includes('servicos/aluguel-de-onibus')) {
            result = 'Veja as principais vantagens em alugar um ônibus com a Real Expresso. Aqui você encontra conforto, segurança, suporte operacional em todo o país. Confira.';
        } else if (url.includes('/onibus')) {
            result = 'Conheça todas as cidades atendidas pela empresa de ônibus Real Expresso. Clique e veja as principais rotas para cada destino. Confira!';
        } else {
            result = 'Compre sua passagem de ônibus barata e viaje com todo conforto e segurança que você e a sua família merece. Temos ônibus leito, semi-leito e executivo';
        }

        return result;
    }
    
    resolveGuanabaraUrl = (url: string): string => {
        url = this.removeParamsFromUrl(url);
        let result = '';

        if (url.includes('faq')) {
            result = 'Perguntas sobre como viajar de ônibus';
        } else if (url.includes('horarios-de-agencias')) {
            result = 'Horário de atendimento dos guichês';
        } else if (url.includes('/onibus')) {
            result = 'Cidades atendidas para viagem de ônibus';
        } else if (url.includes('privacidade')) {
            result = 'Política de privacidade da empresa';
        } else if (url.includes('termos')) {
            result = 'Termos de serviços de viagem de ônibus';
        } else if (url.includes('institucional')) {
            result = 'História sobre a empresa de ônibus';
        } else {
            result = 'Compre sua Passagem de ônibus online';
        }

        return result + ' – Expresso Guanabara';
    }

    resolveGuanabaraMetadata = (url: string): string => {
        url = this.removeParamsFromUrl(url);
        let result = '';

        if (url.includes('faq')) {
            result = 'Nesta página de perguntas e resposta você encontra diversos questionamentos sobre como viajar de ônibus com a Expresso Guanabara. Clique e confira!';
        } else if (url.includes('horarios-de-agencias')) {
            result = 'Confira os horários e as cidades do guichê mais perto do seu destino e veja como comprar sua passagem de ônibus em qualquer local do Brasil';
        } else if (url.includes('/onibus')) {
            result = 'Veja as cidades brasileiras atendidas pela Expresso Guanabara e programe a sua viagem de ônibus com todo o conforto e segurança que você merece';
        } else if (url.includes('privacidade')) {
            result = 'Conheça toda a política de privacidade da Expresso Guanabara e veja as informações sobre armazenamento de dados. Aqui você viaja de ônibus com segurança';
        } else if (url.includes('termos')) {
            result = 'Veja os termos e condições de uso dos serviços de viagem de ônibus prestados pela Expresso Guanabara. Conheça os detalhes!';
        } else if (url.includes('institucional')) {
            result = 'Conheça toda a história da Expresso Guanabara que atua no mercado de viagem de ônibus há mais de 20 anos e atua em 14 estados brasileiros. Compre online';
        } else {
            result = 'Compre as passagens de ônibus da Expresso Guanabara. Garanta sua passagem online, com antecedência, segurança e conforto. Acesse o site e garanta já a sua!';
        }

        return result;
    }
}