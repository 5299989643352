import { Serializer } from "../models";

export class EmptySerializer implements Serializer {
    fromJson(json: any): any {
        return json;
    }

    fromJsonCollection(json: any): any[] {
       return json;
    }
}