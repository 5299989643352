
export default interface PassengerType {
    idPassengerType: number;
    name: string;
    remarks: string;
    isDefault: boolean;
    passengerClassification: PassengerClassification    
}

export interface PassengerClassification {
    idPassengerClassification: number;
    name: string;
    remarks: string;
    classificationType: PassengerClassificationType;
    typeName: string;
    info: string;
}

export enum PassengerClassificationType {
    Normal = 1,
    Elderly = 2,
    Child = 3,
    Deficient = 4,
    Student = 5,
    DomesticAnimal = 6,
    CollectiveAgreement = 7,
    ProfessionalInDisplacement = 8,
    CompanyProfile = 9,
    Young = 10,
    Others = 99,
}