import { Injectable, APP_INITIALIZER } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { GtmAnnouncerService } from "..";
import { filter } from "rxjs";
import { HomeViewEvent } from "../google-tag-manager/events/home-view.event";

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    constructor(
        private router: Router,
        private gtmAnnouncerService: GtmAnnouncerService
    ) {
        this.initialize();
    }

    private initialize() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            if (this.isHomePage(event.url)) {
                const homeViewEvent = new HomeViewEvent();
                this.gtmAnnouncerService.announceEvent(homeViewEvent);
            }
        });
    }

    private isHomePage(url: string): boolean {
        const isHome = url === '/' || url === '';
        return isHome;
    }
}

export function initializeNavigationService(navigationService: NavigationService) {
    return () => {};  // Service is initialized in constructor
}

// Provider to ensure the service is initialized
export const NavigationServiceProvider = {
    provide: APP_INITIALIZER,
    useFactory: initializeNavigationService,
    deps: [NavigationService],
    multi: true
};