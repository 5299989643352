import { ICompanyConfig } from "../config/company-config";

export class CustomerCompleteRegisterEvent {
    readonly email: string;
    readonly pass: string;
    readonly passConfirmation: string;
    readonly idCountry: number;
    readonly countryCode: string;
    readonly origin: number;
    readonly idCity: number;
    readonly idState: number;
    readonly birthday: Date;
    readonly document: string;
    readonly acceptCommunication: boolean;

    constructor(
        email: string,
        pass: string,
        passConfirmation: string,
        idCity: number,
        idState: number,
        birthday: Date,
        document: string,
        idCountry:number,
        countryCode:string,
        acceptCommunication: boolean,
        config: ICompanyConfig
    ) {
        this.email = email;
        this.pass = pass;
        this.passConfirmation = passConfirmation;
        this.idCity = idCity;
        this.idState = idState;
        this.birthday = birthday;
        this.idCountry = config.idTenant !== 2 ? config.countryId : idCountry;
        this.countryCode = config.idTenant !== 2 ? '55' : countryCode;
        this.origin = config.origin;
        this.document = document;
        this.acceptCommunication = acceptCommunication;
    }
}