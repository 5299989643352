export class StringHelper {
    static normalizeString = (term: string): string => {
        if(term && term != ''){
            term = term.replace(/[ÀÁÂÃÄÅ]/, "A");
            term = term.replace(/[àáâãäå]/, "a");
            term = term.replace(/[ÈÉÊË]/, "E");
            term = term.replace(/[èéêë]/, "e");
            term = term.replace(/[ÌÍÎ]/, "I");
            term = term.replace(/[ìíî]/, "i");
            term = term.replace(/[ÒÓÔ]/, "O");
            term = term.replace(/[òóô]/, "o");
            term = term.replace(/[ÙÚÛ]/, "U");
            term = term.replace(/[ùúÛ]/, "u");
            term = term.replace(/[Ç]/, "C");
            term = term.replace(/[ç]/, "c");

            term = term.toLowerCase().trim();
        }

        return term;
    }

}