import { Dropdown } from "../models/helpers/dropdown";
import { Serializer } from "../models/helpers/serializer";

export class DropdownSerializer implements Serializer {
    fromJson(json: any): Dropdown {
        const dropdown: Dropdown = Object.assign(new Dropdown(), json);
        return dropdown;
    }

    fromJsonCollection(jsonColletion: any): Dropdown[] {
        const result: Dropdown[] = [];

        jsonColletion.forEach(element => {
            const item: Dropdown = Object.assign(new Dropdown(), element);
            result.push(item);
        });

        return result;
    }
}