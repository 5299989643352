import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Dropdown } from "../models";
import { DropdownSerializer, EmptySerializer } from "../serializers";
import { BaseApi } from "./base.api";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: 'root'
})
export class PassengerClassificationService {

    private api: BaseApi;

    public constructor(
        base: BaseService
    ) {
        this.api =  base.setApi('gateway', 'core/PassengerClassification');
    }


    public getGratuityDiscountTypesDropDown(): Observable<Dropdown[]> {
        return this.api.getAsCollection('GetGratuityDiscountTypes', new DropdownSerializer());
    }
}