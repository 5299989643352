import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { ALL_MAIN_COMPONENTS } from "./components";
import { ALL_MAIN_HELPERS } from "./helpers";
import { ALL_MAIN_PIPES } from "./pipes";
import { ALL_INITIALIZERS, ALL_MAIN_SERVICES } from "./services";
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbTypeaheadModule, NgbDatepickerModule, NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ALL_MAIN_INTERCEPTORS } from "./interceptors";
import { RouterModule } from "@angular/router";

const ALL_MAIN_NG_BOOTSTRAP = [
    NgbTypeaheadModule,
    NgbDatepickerModule,
    NgbCarouselModule
]

@NgModule({
    declarations: [
        ALL_MAIN_COMPONENTS,
        ALL_MAIN_PIPES
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        TranslateModule,
        CarouselModule,
        RouterModule,
        ALL_MAIN_NG_BOOTSTRAP
    ],
    providers: [
        ALL_INITIALIZERS,
        ALL_MAIN_INTERCEPTORS
    ],
    exports: [
        CommonModule,
        ALL_MAIN_COMPONENTS,
        ALL_MAIN_PIPES,
        CarouselModule,
        ALL_MAIN_NG_BOOTSTRAP,
        ReactiveFormsModule,
        TranslateModule
    ]
})
export class MainModule {

    public static forRoot(): ModuleWithProviders<MainModule> {
        return {
            ngModule: MainModule,
            providers: [
                ALL_MAIN_SERVICES,
                ALL_MAIN_HELPERS
            ]
        };
    }

}
