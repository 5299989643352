import { LocationHelper } from "src/app/main/helpers";

export class ScheduleLocation {
    Name: string;
    PrettyName: string;

    setName = (name: string): void => {
        this.Name = name;
        this.PrettyName = LocationHelper.beautifyLocationName(this.Name);
    }
}