import { ChangeDetectionStrategy, Component, input } from '@angular/core';

interface SkeletonStyles {
  width?: string;
  height?: string;
  'border-radius'?: string;
}

@Component({
  selector: 'app-skeleton-loader',
  template: `
    <div 
      [ngStyle]="computedStyles()" 
      class="skeleton-loader"
      [attr.aria-busy]="true"
      role="progressbar">
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonLoaderComponent {
  width = input<string>('');
  height = input<string>('');
  circle = input<boolean>(false);

  protected computedStyles(): SkeletonStyles {
    return {
      width: this.width() || '',
      height: this.height() || '',
      'border-radius': this.circle() ? '50%' : ''
    };
  }
}
