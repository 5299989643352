export * from './browser.helper';
export * from './company.helper';
export * from './date.helper';
export * from './location.helper';
export * from './storage.helper';
export * from './string.helper';
export * from './image.helper';
export * from './seo.helper';
export * from './session-storage.helper';

import { BrowserHelper } from './browser.helper';
import { CompanyHelper } from './company.helper';
import { StorageHelper } from './storage.helper';
import { ImageHelper } from './image.helper';
import { SeoHelper } from './seo.helper';
import { SessionStorageHelper } from './session-storage.helper';
export const ALL_MAIN_HELPERS = [
    BrowserHelper,
    StorageHelper,
    CompanyHelper,
    ImageHelper,
    SeoHelper,
    SessionStorageHelper
]