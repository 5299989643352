import { Injectable } from '@angular/core';
import { ConfigService } from '../services/config.service';

@Injectable({
    providedIn: 'root'
})
export class ImageHelper {

    public constructor(
        private configService: ConfigService
    ) {
    }

    public getImageLocation(token: string): string {
        const configLocation = this.configService.config.configLocation
            .replace('{{company}}', this.configService.config.company);
        return `${configLocation}sites/${token}`;
    }
}