import { Component, ViewEncapsulation, OnInit, HostListener, Output, EventEmitter, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import PassengerType, { PassengerClassification, PassengerClassificationType } from "src/app/main/models/passanger/passenger-type";
import { StorageService } from "src/app/main/services";
import { PassengerTypeService } from "src/app/main/services/passengerType.service";


@Component({
    selector: 'passenger-types-select',
    templateUrl: './passenger-types-select.html',
    styleUrls: ['./passenger-types-select.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PassengerTypesSelectComponent implements OnInit {
    groupedPassengerTypes: IGroupedPassengerType = {};

    isOpen: boolean = false;
    error: boolean = false;

    @Output() onSelectedCountChange = new EventEmitter<{ group: IGroupedPassengerType, value: string }>();
    @Input("value") valueInput: string;
    get value(): string {
        let values: string[] = [];
        for (const key in this.groupedPassengerTypes) {
            var it = this.groupedPassengerTypes[key];
            if (it.selectedQtd > 0) {
                values.push(it.passengerClassification.idPassengerClassification + ':' + it.selectedQtd);
            }
        }
        return values.join(',');
    }

    get totalQtd(): number {
        let total = 0;
        for (const key in this.groupedPassengerTypes) {
            total += this.groupedPassengerTypes[key].selectedQtd;
        }
        return total;
    }

    get totalQtdStr(): string {
        return this.totalQtd + ' ' + this.translateService.instant("search.passenger") + (this.totalQtd != 1 ? 's' : '');
    }

    @HostListener('document:click', ['$event'])
    close(ev: MouseEvent) {
        if (ev.target && (ev.target as HTMLElement).closest('passenger-types-select')) {
            return;
        }
        this.isOpen = false;
    }

    toggleAndScroll(): void {
        this.isOpen = !this.isOpen;

        setTimeout(() => {
            this.scrollPassengerTypesIntoView();
        }, 200);
    }

    constructor(
        private passengerTypeService: PassengerTypeService,
        private translateService: TranslateService,
        private storage: StorageService) {
    }

    ngOnInit(): void {
        this.groupedPassengerTypes = {} as IGroupedPassengerType;
        this.passengerTypeService.GetAllPassengerTypesInPointOfSale().then(passengerTypes => {

            this.storage.setPassengerTypes(passengerTypes);            

            var dict: { [idPassengerClassification: number]: number } = undefined;

            if (this.valueInput) {
                dict = {};
                this.valueInput.split(',').forEach(item => {
                    dict[parseInt(item.split(':')[0])] = parseInt(item.split(':')[1]);
                });
            }

            passengerTypes.forEach(passengerType => {
                if (!this.groupedPassengerTypes[passengerType.passengerClassification.idPassengerClassification]) {
                    this.groupedPassengerTypes[passengerType.passengerClassification.idPassengerClassification] = { passengerClassification: passengerType.passengerClassification, passengerTypeIds: [], selectedQtd: 0, passengerTypes: [] };
                }
                this.groupedPassengerTypes[passengerType.passengerClassification.idPassengerClassification].passengerTypeIds.push(passengerType.idPassengerType);
                this.groupedPassengerTypes[passengerType.passengerClassification.idPassengerClassification].passengerTypes.push(passengerType);

                if (dict) {
                    if (passengerType.passengerClassification.idPassengerClassification in dict) {
                        this.groupedPassengerTypes[passengerType.passengerClassification.idPassengerClassification].selectedQtd = dict[passengerType.passengerClassification.idPassengerClassification];
                    }
                    return;
                }

                if (passengerType.isDefault) {
                    this.groupedPassengerTypes[passengerType.passengerClassification.idPassengerClassification].selectedQtd = 1;
                }

            });
            window.groupedPassengerTypes = this.groupedPassengerTypes;
            this.onSelectedCountChange.emit({ group: this.groupedPassengerTypes, value: this.value });
        });
    }

    scrollPassengerTypesIntoView(): void {
        const passangerTypes = document.querySelector('.passenger-types-select.is-open')
        const rect = passangerTypes.getBoundingClientRect();
        const viewHeight = window.innerHeight;
        
        if (passangerTypes && (rect.bottom > viewHeight)) {
            const offset = rect.bottom - viewHeight + 20;
            window.scrollBy({ top: offset, behavior: 'smooth' });
        }
    }

    decreasePassengerType(info: IGroupedItemInfo, isDeficient = false) {

        this.error = false;

        if(info.passengerClassification.classificationType == PassengerClassificationType.Deficient && info.selectedQtd <= 2 && !isDeficient){
            return; 
        }

        if (info.passengerClassification.classificationType != PassengerClassificationType.Child) {

            //get child
            var child;
            for (const key in this.groupedPassengerTypes) {
                if (this.groupedPassengerTypes[key].passengerClassification.classificationType == PassengerClassificationType.Child) {
                    child = this.groupedPassengerTypes[key];
                    break;
                }
            }

            if((child && child.selectedQtd > 0 && this.totalQtd == 2) || this.totalQtd <= 1){
                return;
            }
        }

        if (info.selectedQtd < 1) {
            return;
        }

        info.selectedQtd = (info.selectedQtd - 1);
        this.onSelectedCountChange.emit({ group: this.groupedPassengerTypes, value: this.value });

        var max = this.getMaxPassengerQuantityForChild();
        var child;
        for (const key in this.groupedPassengerTypes) {
            if (this.groupedPassengerTypes[key].passengerClassification.classificationType == PassengerClassificationType.Child) {
                child = this.groupedPassengerTypes[key];
                break;
            }
        }

        if (child && child.selectedQtd > max) {
            child.selectedQtd = max;
        }

    }

    increasePassengerType(info: IGroupedItemInfo, isDeficient = false) {

        if(info.passengerClassification.classificationType == PassengerClassificationType.Deficient && info.selectedQtd > 0 && !isDeficient){
            return; 
        }

        this.error = false;
        this.resetSelectedPassengerTypesIfNecessary(info);
        var max = this.getMaxPassengerQuantity(info.passengerClassification.classificationType);
        if (info.selectedQtd >= max) {
            return;
        }
        info.selectedQtd = (info.selectedQtd + 1);
        this.onSelectedCountChange.emit({ group: this.groupedPassengerTypes, value: this.value });
    }


    private resetSelectedPassengerTypesIfNecessary(info: IGroupedItemInfo) {


        var resetAllTypes = false;
        for (const key in this.groupedPassengerTypes) {
            if (this.groupedPassengerTypes[key].selectedQtd > 0
                && this.groupedPassengerTypes[key].passengerClassification.classificationType != PassengerClassificationType.Child
                && PassengerClassificationType.Child != info.passengerClassification.classificationType) {
                if (this.groupedPassengerTypes[key] != info) {
                    resetAllTypes = true;
                    break;
                }
            }
        }        

        if (resetAllTypes) {
            this.error = true;
            for (const key in this.groupedPassengerTypes) {
                if (this.groupedPassengerTypes[key].selectedQtd > 0) {
                    this.groupedPassengerTypes[key].selectedQtd = 0;
                }
            }
        }
    }

    getMaxPassengerQuantity(classificationType: PassengerClassificationType) {
        switch (classificationType) {
            case PassengerClassificationType.Normal:
                return 6;

            case PassengerClassificationType.Deficient:
                return 2;

            case PassengerClassificationType.Child:
                return this.getMaxPassengerQuantityForChild();

            default:
                return 1;
        }
    }

    PassengerClassificationType = PassengerClassificationType;

    getMaxPassengerQuantityForChild() {

        var currentType: IGroupedItemInfo;

        for (const key in this.groupedPassengerTypes) {
            if (this.groupedPassengerTypes[key].selectedQtd > 0 && this.groupedPassengerTypes[key].passengerClassification.classificationType != PassengerClassificationType.Child) {
                currentType = this.groupedPassengerTypes[key];
                break;
            }
        }

        return currentType.selectedQtd;
    }
}



export interface IGroupedPassengerType { [idPassengerClassification: number]: IGroupedItemInfo }
export interface IGroupedItemInfo {
    passengerTypeIds: number[];
    passengerTypes: PassengerType[];
    passengerClassification: PassengerClassification;
    selectedQtd: number;
}
