import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, Output, EventEmitter, HostListener } from '@angular/core';
import { ImageHelper } from 'src/app/main/helpers';
import { Feature } from 'src/app/main/models';

@Component({
    selector: 'app-feature',
    templateUrl: './feature.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureComponent {
    @Input('feature') feature: Feature;
    @Output('onSlide') onSlide: EventEmitter<any> = new EventEmitter();

    xDown = null;                                                        
    yDown = null;

    swipeDirection = 'none';

    constructor(private imageHelper: ImageHelper) {
    }

    @HostListener('touchstart', ['$event'])
    handleTouchStart(event) {
        const firstTouch = event.touches[0];                                      
        this.xDown = firstTouch.clientX;                                      
        this.yDown = firstTouch.clientY;
        
    };

    @HostListener('touchmove', ['$event'])
    handleTouchMove(event) {
        this.swipeDirection = this.setSwipeDirection(event)

        this.onSlide.emit({
            'touchLeft': this.swipeDirection == `left` ? true : false
        })
    }

    setSwipeDirection(evt): string {
        if (!this.xDown || !this.yDown) {
            return;
        }
    
        let xUp = evt.touches[0].clientX;                                    
        let yUp = evt.touches[0].clientY;
    
        let xDiff = this.xDown - xUp;
        let yDiff = this.yDown - yUp;
    
        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if ( xDiff > 0 ) {
                this.swipeDirection = 'left'
            } else {
                /* right swipe */
                this.swipeDirection = 'right'
            }                       
        } else {
            if ( yDiff > 0 ) {
                /* up swipe */
                this.swipeDirection = 'up' 
            } else { 
                /* down swipe */
                this.swipeDirection = 'down'
            }                                                                 
        }
        /* reset values */
        this.xDown = null;
        this.yDown = null;
        
        return this.swipeDirection;
    };
    
}