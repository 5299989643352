import { Compiler, Injectable, Injector } from "@angular/core";
import { environment } from "src/environments/environment";
import { ICompany } from "../models";
import { ConfigService } from "./config.service";

@Injectable()
export class CompanyService {

    public company: ICompany;

    public constructor(
        private configService: ConfigService,
        private compiler: Compiler,
        private injector: Injector
    ) {
        if (environment.production && location.protocol === 'http:') {
            window.location.href = location.href.replace('http', 'https');
        }
    }

    public load(): Promise<any> {
        switch (this.configService.config.company) {
            // case "gipsyy": return this.loadGipsyy();
            // case "gipsyy-eu": return this.loadGoGipsyyEu();
            // case "guanabara": return this.loadGuanabara();
            // case "real-rapido": return this.loadRealRapido();
            // case "royal-express": return this.loadRoyalExpress();
            // case "sampaio": return this.loadSampaio();
            // case "sao-benedito": return this.loadSaoBenedito();
            case "aguiar": return this.loadAguiar();
            case "expresso-mato-grosso-do-sul": return this.loadExpressoMatoGrossoDoSul();
            case "expresso-pantanal": return this.loadExpressoPantanal();
            case "motta": return this.loadMotta();
            case "princesa": return this.loadPrincesa();
            case "progresso": return this.loadProgresso();
            case "serro": return this.loadSerro();
            case "smart-travel": return this.loadSmartTravel();
            case "teixeira": return this.loadTeixeira();
            case "util": return this.loadUtil();
            case "vtr": return this.loadVTR();
            default: throw `Invalid company '${this.configService.config.company}!!!`;
        }
    }

    private loadAguiar(): Promise<any> {
        return import('src/app/company/aguiar/main/aguiar-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.AguiarMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }
    private loadExpressoMatoGrossoDoSul(): Promise<any> {
        return import('src/app/company/expresso-mato-grosso-do-sul/main/expresso-mato-grosso-do-sul-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.ExpressoMatoGrossoDoSulMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }

    private loadExpressoPantanal(): Promise<any> {
        return import('src/app/company/expresso-pantanal/main/expresso-pantanal-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.ExpressoPantanalMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }

    private loadMotta(): Promise<any> {
        return import('src/app/company/motta/main/motta-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.MottaMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }

    private loadPrincesa(): Promise<any> {
        return import('src/app/company/princesa/main/princesa-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.PrincesaMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }

    private loadProgresso(): Promise<any> {
        return import('src/app/company/progresso/main/progresso-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.ProgressoMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }

    private loadSerro(): Promise<any> {
        return import('src/app/company/serro/main/serro-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.SerroMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }

    private loadSmartTravel(): Promise<any> {
        return import('src/app/company/smart-travel/main/smart-travel-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.SmartTravelMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }

    private loadTeixeira(): Promise<any> {
        return import('src/app/company/teixeira/main/teixeira-main.module')
        .then(mod => this.compiler.compileModuleAsync(mod.TeixeiraMainModule))
        .then(mod => {
            this.company = mod.create(this.injector).instance;
        });
    }    

    private loadUtil(): Promise<any> {
        return import('src/app/company/util/main/util-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.UtilMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }


    private loadVTR(): Promise<any> {
        return import('src/app/company/vtr/main/vtr-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.VtrMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }
    
    /*
    private loadRealRapido(): Promise<any> {
        return import('src/app/company/real-rapido/main/real-rapido-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.RealRapidoMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }

    private loadRoyalExpress(): Promise<any> {
        return import('src/app/company/royal-express/main/royal-express-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.RealExpressMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }
    private loadSampaio(): Promise<any> {
        return import('src/app/company/sampaio/main/sampaio-main.module')
        .then(mod => this.compiler.compileModuleAsync(mod.SampaioMainModule))
        .then(mod => {
            this.company = mod.create(this.injector).instance;
        });
    }

    private loadSaoBenedito(): Promise<any> {
        return import('src/app/company/sao-benedito/main/sao-benedito-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.SaoBeneditoMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }

    private loadGipsyy(): Promise<any> {
        return import('src/app/company/gipsyy/main/gipsyy-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.GipsyyMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }

    private loadGoGipsyyEu(): Promise<any> {
        return import('src/app/company/gipsyy-eu/main/go-gipsyy-eu-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.GoGipsyyEuMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }

    private loadGuanabara(): Promise<any> {
        return import('src/app/company/guanabara/main/guanabara-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.GuanabaraMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }
    */
}