import { APP_INITIALIZER, Injectable, Provider } from '@angular/core';
import { CompanyService } from './company.service';
import { ConfigService } from './config.service';

@Injectable()
export class InitializerService {

    public constructor(
        private configService: ConfigService,
        private companyService: CompanyService
    ) {
    }

    public async load(): Promise<void> {
        await this.configService.load();
        await this.configService.loadCompany();
        await Promise.all([
            this.configService.loadSiteConfig(),
            this.companyService.load()
        ]);
        await this.companyService.company.configure();
        //await this.wait();
    }

    public wait(): Promise<void> {
        return new Promise((e, r) => {
            setTimeout(e, 100000);
        });
    }
}

export function InitializerProviderFactory(initializertService: InitializerService) {
    return () => initializertService.load();
}

export const InitializerProvider: Provider = {
    provide: APP_INITIALIZER,
    useFactory: InitializerProviderFactory,
    deps: [InitializerService],
    multi: true
};
