import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BrowserHelper, ImageHelper } from '../../../helpers';
import { Banner, Category } from '../../../models';

@Component({
    selector: 'app-owl-carousel',
    templateUrl: './owl-carousel.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OwlCarouselComponent implements OnChanges {
    @Input('categories') categories: Category[];
    displayCategories: Category[] = [];
    selectedCategory: Category;
    banners: Banner[] = [];
    displayBanners: Banner[] = [];
    currentDom: any;
    isDragging: boolean = false;

    constructor(public imageHelper: ImageHelper,
        public browserHelper: BrowserHelper) {
        this.currentDom = window;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.categories && this.categories.length > 0) {
            this.displayCategories = this.categories.filter(c => c.banners.length > 0);

            if (this.displayCategories.length === 0) {
                return;
            }

            const defaultCateg = this.displayCategories.find(c => c.isDefault);
            if (defaultCateg) {
                this.displayBanners = defaultCateg.banners;
                this.selectedCategory = defaultCateg;
            } else {
                const someCateg = this.displayCategories[0];
                this.displayBanners = someCateg.banners;
                this.selectedCategory = someCateg;
            }
        }
    }

    filterBanners = (idSiteBannerCategory: number) => {
        this.selectedCategory = this.categories.find(c => c.idSiteBannerCategory == idSiteBannerCategory);
        if (!this.selectedCategory) {
            return;
        }
        this.displayBanners = this.selectedCategory.banners;
    }

    customOptions: OwlOptions = {
        mouseDrag: true,
        touchDrag: true,
        margin: 20,
        responsive: {
            0: {
                items: 1,
                dots: true
            },
            576: {
                items: 2,
            },
            992: {
                items: 3,
                dots: false,
            }
        }
    };

    onCarouselDrag(dragging: boolean): void {
        setTimeout(() => {
            this.isDragging = dragging;
        }, 10)
    }

    openLink = (link: string): void => {
        if (this.isDragging) {
            return;
        }


        if (!link) {
            return;
        }

        window.open(link, '_blank');
    }
}
