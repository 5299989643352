<div *ngIf="features" class="company-features-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="company-features">
                    <img *ngIf="isMobile || features.length > 5" (click)="rotate(true)" class="arrow" alt="Rolar"
                         src="assets/_backup/ecommerce/imgs/common-guanabara/home-company-features/company-features-arrow.png" />

                    <app-feature *ngFor="let feature of displayFeatures" [feature]="feature" (onSlide)="setSlideEvent($event)"></app-feature>

                    <img *ngIf="isMobile || features.length > 5" (click)="rotate(false)" class="arrow rotate" alt="Rolar"
                         src="assets/_backup/ecommerce/imgs/common-guanabara/home-company-features/company-features-arrow.png" />
                </div>
            </div>
        </div>
    </div>
</div>
