<div class="container" *ngIf="displayCategories && displayCategories.length > 0">
    <div class="row">
        <div class="col-md-12">
            <div class="owl-carousel-filters tags">
                <div *ngFor="let category of displayCategories" (click)="filterBanners(category.idSiteBannerCategory)"
                     [ngClass]="category.idSiteBannerCategory === selectedCategory.idSiteBannerCategory ? 'owl-carousel-filter active' : 'owl-carousel-filter'">
                    {{category.name}}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-carousel">
    <owl-carousel-o *ngIf="displayBanners && displayBanners.length > 0" [options]="customOptions" (dragging)="onCarouselDrag($event.dragging)">
        <ng-container *ngFor="let banner of displayBanners">
            <ng-template carouselSlide [id]="'item_' + i">
                <a (click)="openLink(banner.link)">
                    <img [src]="imageHelper.getImageLocation(banner.imageToken)" [alt]="banner.altText" [title]="banner.altText" class="img-slide" />
                </a>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
