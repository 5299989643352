import { BookingSummaryRoute } from 'src/app/core';
import { OreonsDatetime, OreonsDuration } from '../helpers/oreons-datetime';
import { TripKey } from './trip-key';
import { TripRoute } from './trip-route';
import { GroupedTripSearchPrice, TripSearchPrice } from './trip-search-price';

export class Trip {
    allowBooking: boolean;
    arrivalDateTime: OreonsDatetime;
    arrivalLocationCode: string;
    arrivalLocationName: string;
    availableSeats: number;
    availableSeatsPercent: number;
    boardingPlatform: string;
    businessType: null
    capacity: number;
    classOfServiceName: string;
    companyName: string;
    controlNumber: string;
    currentCulture: null
    departureArrivalDiffDays: number;
    departureDateTime: OreonsDatetime;
    departureDateTimeString: string;
    departureLocationCode: string;
    departureLocationName: string;
    duration: OreonsDuration;
    idArrivalLocation: number;
    idClassOfService: number;
    idCompany: number;
    idDepartureLocation: number;
    isAvailable: boolean;
    prices: TripSearchPrice[] = [];
    originalPrices: TripSearchPrice[] = [];
    routes: TripRoute[] = [];
    renderNextDay: boolean;
    tripKey: TripKey;
    departureLocationDescription: string;
    departureDateTimeOffset: number;
    arrivalDateTimeOffset: number;


    get isGratuitFree(): boolean {

        var gprice = this.getMinimumPrice() as GroupedTripSearchPrice;

        for (let priceKey in gprice.originalData) {
            let price = gprice.originalData[priceKey];

            if(!price.discountType || price.discountType === 0 || price.discountType === 1 || price.requireExistingSeat){
                continue;
            }

            if (price.fare.currencyValue === 0) {
                return true;
            }
        }

        return false;
    }

    get isGratuitDiscount(): boolean {
        var gprice = this.getMinimumPrice() as GroupedTripSearchPrice;        
        var isDiscountType = false;
        
        for (let priceKey in gprice.originalData) {
            let price = gprice.originalData[priceKey];

            if(!price.discountType || price.discountType === 0  || price.discountType === 1 || price.requireExistingSeat){
                continue;
            }

            isDiscountType = true;

            if (price.fare.currencyValue === 0) {
                return false;
            }
        }

        return isDiscountType;
    }


    minimumPrice = (includeConvenienceFee?: boolean): number => {
        var byType: { [idPassengerClassification: number]: TripSearchPrice } = {};
        for (let priceKey in this.prices) {
            let price = this.prices[priceKey];

            if (!byType[price.idPassengerClassification]) {
                byType[price.idPassengerClassification] = price;
            } else if (price.total.currencyValue < byType[price.idPassengerClassification].total.currencyValue) {
                byType[price.idPassengerClassification] = price;
            }
        }

        var sumPrice = this.sumPrices(byType);

        if (!includeConvenienceFee) {
            {
                var convenienceFee = sumPrice.convenienceFee.currencyValue;
                return sumPrice.total.currencyValue === Number.MAX_SAFE_INTEGER ? 0 : sumPrice.total.currencyValue - convenienceFee;
            }
        }

        return sumPrice.total.currencyValue;
    }

    getMinimumPrice = (): any => {


        var byType: { [passengerType: number]: TripSearchPrice } = {};

        for (let priceKey in this.prices) {
            let price = this.prices[priceKey];

            if (!byType[price.idPassengerClassification]) {
                byType[price.idPassengerClassification] = price;
            } else if (price.total.currencyValue < byType[price.idPassengerClassification].total.currencyValue) {
                byType[price.idPassengerClassification] = price;
            }

        }

        return this.sumPrices(byType);
    }

    sumPrices(priceDict: { [passengerType: number]: TripSearchPrice }): GroupedTripSearchPrice {

        let total = 0;
        let convenienceFee = 0;
        let hasOptionalInsurance = false;
        let optionalInsuranceValue = 0;
        let idPassengerType = 0;
        let fareFamilyCode = "";
        let code = 0;
        let currencyInfo;
        let fare = 0;

        var originalData = [];

        for (let priceKey in priceDict) {
            let price = priceDict[priceKey];
            total += price.total.currencyValue;
            convenienceFee += price.convenienceFee.currencyValue;
            hasOptionalInsurance = price.hasOptionalInsurance;
            optionalInsuranceValue += price.optionalInsuranceValue;
            idPassengerType = price.idPassengerType;
            fareFamilyCode = price.fareFamilyCode;
            code = price.code;
            currencyInfo = price.total.currencyInfo;
            originalData.push(price);
            fare += price.fare.currencyValue;
        }

        return {
            total: { currencyValue: total, currencyInfo: currencyInfo },
            convenienceFee: { currencyValue: convenienceFee, currencyInfo: currencyInfo },
            hasOptionalInsurance: hasOptionalInsurance,
            optionalInsuranceValue: optionalInsuranceValue,
            idPassengerType: idPassengerType,
            fareFamilyCode: fareFamilyCode,
            code: code,
            originalData: originalData,
            fare: { currencyValue: fare, currencyInfo: currencyInfo }
        };
    }


    originalMinimumPrice = (includeConvenienceFee?: boolean): number => {

        var byType: { [passengerType: number]: TripSearchPrice } = {};

        for (let priceKey in this.originalPrices) {
            let price = this.prices[priceKey];

            if (!byType[price.idPassengerClassification]) {
                byType[price.idPassengerClassification] = price;
            } else if (price.total.currencyValue < byType[price.idPassengerClassification].total.currencyValue) {
                byType[price.idPassengerClassification] = price;
            }
        }

        var sumPrice = this.sumPrices(byType);

        if (!includeConvenienceFee) {
            {
                var convenienceFee = sumPrice.convenienceFee.currencyValue;
                return sumPrice.total.currencyValue === Number.MAX_SAFE_INTEGER ? 0 : sumPrice.total.currencyValue - convenienceFee;
            }
        }

        return sumPrice.total.currencyValue;

    }

    getPriceByCode = (code: string): number => {
        const result = this.prices.find(price => price.fareFamilyCode.toString() === code);
        if (result === undefined) {
            return 0;
        } else {
            return result.total.currencyValue;
        }
    }

    getPriceByIdFareFamily = (id: number): number => {
        const result = this.prices.find(price => price.code === id);
        if (result === undefined) {
            return 0;
        } else {
            return result.total.currencyValue;
        }
    }

    getDuration = (): number => {
        let departureDate: any = new Date(
            this.departureDateTime.year,
            this.departureDateTime.month - 1,
            this.departureDateTime.day,
            this.departureDateTime.hour,
            this.departureDateTime.minute,
            0);

        let arrivalDate: any = new Date(
            this.arrivalDateTime.year,
            this.arrivalDateTime.month - 1,
            this.arrivalDateTime.day,
            this.arrivalDateTime.hour,
            this.arrivalDateTime.minute,
            0);

        let diff = Math.abs(departureDate - arrivalDate);
        let minutes = Math.floor((diff / 1000) / 60);
        return minutes;
    }
}