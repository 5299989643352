import { OreonsCurrency } from '../helpers/oreons-currency';
import { OreonsDatetime } from '../helpers/oreons-datetime';
import { PassengerDocument } from './passenger-document';

export class Passenger {
    customerCode: string;
    name: string;
    idPassengerType: number;
    idCountry: number;
    idPassengerTypeConfiguration: number;
    passengerKey: string;
    documentNumber: string;
    documents: PassengerDocument[] = [];
    idBookingCoupon: number;
    totalPrice: OreonsCurrency;
    seatIdentifier: string[];
    isOpenRemarkable: boolean;
    miscs: any[];
    birthdate: Date|string;
    birthDate?: OreonsDatetime;
    idPassengerClassification: number;
    phoneNumber?: string;
}
