import { Serializer } from "../models/helpers/serializer";
import { OutletTripRange } from "../models/trip/outlet-trip-range";
import { OutletTripsSearch } from "../models/trip/outlet-trips-search";

export class OutletSearchSerializer implements Serializer {
    fromJson(json: any): any {
        let result = new OutletTripsSearch();
        result.serviceNotConfigured = json.ServiceNotConfigured;
        
        for (let range of json.OutletTripRanges) {
            let newRange = new OutletTripRange(
                range.StartingSaleDateTime,
                range.EndingSaleDateTime,
                range.BestTrip
            );
            result.ranges.push(newRange);
        }

        return result;
    }

    fromJsonCollection(json: any): any[] {
        return json;
    }
}
