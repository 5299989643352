import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID, Injectable } from '@angular/core';

@Injectable()
export class StorageHelper {
    isBrowser: boolean;
    constructor(
        @Inject(PLATFORM_ID) private platform: any
    ) {
        this.isBrowser = isPlatformBrowser(platform);
    }

    setItem = (key: string, value: any): void => {
        if (this.isBrowser) {
            let jsonString = JSON.stringify(value, function(key, val) {
                return (typeof val === 'function') ? '' + val : val;
            });
            localStorage.setItem(key, jsonString);
        }
    }

    private cloneProperties(source, dest): any {
        if(source !== null && dest !== null) {
            for(let property in source) {
                if(source.hasOwnProperty(property)) {
                    if(dest[property] && typeof(dest[property]) == "function")
                        continue;
                    dest[property] = source[property];
                }
            }                                
        }
        return dest;
    }

    getItem(key: string, objToGetMethodsFrom = null): any {
        
        if (this.isBrowser) {        
            
            const json = localStorage.getItem(key);
            let storedObj = (json !== null) ? JSON.parse(json) : null;
            
            if(objToGetMethodsFrom !== null) {
                return this.cloneProperties(storedObj, objToGetMethodsFrom);
            }

            return storedObj;
        }

        return null;
    }

    removeItem = (key: string): void => {
        if (this.isBrowser) {
            localStorage.removeItem(key);
        }
    }
}