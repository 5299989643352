import { BookingSummaryRoute } from "src/app/core/models";
import { AdyenMultibanco } from "../booking-process/adyenMultibanco";
import { OreonsCurrency } from "../helpers/oreons-currency";
import { OreonsDatetime } from "../helpers/oreons-datetime";
import { Passenger } from "../passanger/passenger";
import { BookingSummaryOperation } from "./booking-summary-operation";
import { BookingSummaryPayment } from "./booking-summary-payment";
import { BookingSummaryPrice } from "./booking-summary-price";
import { Customer } from "./customer";
import { BookingSummaryPaymentTransactionData } from "./booking-summary-payment-transaction-data";

export class BookingSummary {
    id: number;
    idBooking: number;
    idUser: number;
    recordLocator: string;
    routes: BookingSummaryRoute[] = [];
    customer: Customer;
    passenger: Passenger;
    penaltyValue: number;
    creditsValue: number;    
    passengers: Passenger[];
    adyenMultibanco: AdyenMultibanco
    outletSale: boolean;
    pricings: BookingSummaryPrice[] = [];
    totalToPay: OreonsCurrency;
    totalPriceValue: OreonsCurrency;
    status: string;
    paymentTransactionsData: BookingSummaryPaymentTransactionData[] = [];
    hasBoardingVoucher: boolean;
    bookingDate: OreonsDatetime;
    payments: BookingSummaryPayment[] = [];
    statusValue: number;
    paymentStatus: number;
    parameters: any;
    companyName: string;
    operations: BookingSummaryOperation[] = [];
    insuranceValues: any[] = [];
    departureLocationLatitude:number;
    departureLocationLongitude:number;
    departureLocationDescription:string;
    additionalData?:{
        BankName:string,
        QrCode:{
            Base64:string,
            Plain:string
        },
        PurchaseExpireIn:string,
        Instructions:{
            QrCode:string[];
        }
        
    }
}