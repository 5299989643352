import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApi, BaseService, OreonsDatetime, OreonsCurrency, SeatPrice } from 'src/app/main';
import { SeatPriceSerializer } from '../serializers';

@Injectable({
    providedIn: 'root'
})
export class SeatMapPricingService {

    private api: BaseApi;
    
    public constructor(
        base: BaseService
    ) {
        this.api =  base.setApi('pricing', 'SeatMapPricing');
    }

    public getSeatPricesByFilter(
        idVehicleConfiguration: number,
        idSchedule: number,
        idDepartureLocation: number,
        idArrivalLocation: number,
        departureDateTime: OreonsDatetime,
        fareValue: OreonsCurrency): Observable<SeatPrice[]> {
        const data = {
            IdVehicleConfiguration: idVehicleConfiguration,
            IdSchedule: idSchedule,
            IdDepartureLocation: idDepartureLocation,
            IdArrivalLocation: idArrivalLocation,
            DepartureDateTime: departureDateTime,
            FareValue: fareValue
        };

        return this.api.postAsCollection('GetSeatPricesByFilterNoAuth', data, new SeatPriceSerializer())
    }
}
