
export class LocationInfo {
    locationName: string;
    pointOfSale: string;
    pointOfSaleName: string;
    address: string;
    openHours: LocationInfoOpenHour[] = [];
}

export interface LocationInfoOpenHour {
    daysOfWeek: DaysOfWeek;
    startTime: number;
    endTime: number;

  
}

export enum DaysOfWeek {
    Sunday = 1,
    Monday = 2,
    Tuesday = 3,
    Wednesday = 4,
    Thursday = 5,
    Friday = 6,
    Saturday = 7
}