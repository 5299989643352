import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICompanyConfig } from '../models';
import { ConfigService } from '../services/config.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyHelper {
    
    private config: ICompanyConfig;

    public constructor(
        private translateService: TranslateService,
        configService: ConfigService
    ) { 
        this.config = configService.company;
    }

    public getCompanyEmail(): string {
        if (this.config.idTenant === 1) {
            return this.translateService.instant('common.utilEmail');
        } else if (this.config.idTenant === 3) {
            return this.translateService.instant('common.sampaioEmail');
        } else if (this.config.idTenant === 4) {
            return this.translateService.instant('common.rrEmail');
        } else if (this.config.idTenant === 5) {
            return this.translateService.instant('common.guanabaraEmail');
        }
    }

    public getCompanyPhone(): string {
        if (this.config.idTenant === 1) {
            return this.translateService.instant('common.utilPhone');
        } else if (this.config.idTenant === 3) {
            return this.translateService.instant('common.sampaioPhone');
        } else if (this.config.idTenant === 4) {
            return this.translateService.instant('common.rrPhone');
        } else if (this.config.idTenant === 5) {
            return this.translateService.instant('common.guanabaraPhone');
        }
    }

    public getCompanyName(): string {
        if (this.config.idTenant === 1) {
            return 'UTIL'
        } else if (this.config.idTenant === 3) {
            return 'Sampaio'
        } else if (this.config.idTenant === 4) {
            return 'Real Expresso | Rapido Federal'
        } else if (this.config.idTenant === 5) {
            return 'Expresso Guanabara'
        }
    }
}