import { Travel } from '../models/travel';

export class BookingEvent {
    event: string = 'booking';
    travel: Travel;
    ecommerce: any;

    constructor(
        departureLocation: string,
        departureDate: string,
        arrivalLocation: string,
        arrivalDate: string,
        cupon: string,
        serviceOfClassName: string,
        isReturn: boolean
    ) {
        this.travel = {
            originBusStation: departureLocation,
            departingDepartureDate: departureDate,
            destinationBusStation: arrivalLocation,
            returningDepartureDate: arrivalDate,
            cuponCode: cupon,
            travelClass: serviceOfClassName
        };
        if (!isReturn) {
            this.ecommerce = {
                checkout: {
                    actionField: {
                        step: 3,
                    }
                }
            }
        }
    }
}