import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { BaseApi, BaseService, EmptySerializer } from "src/app/main";
import { PassengerTypeConfiguration } from "../models/booking-process/passenger-type-configuration";
import { InfoMetadata } from "../models/booking-process/info-metadata";

@Injectable({
    providedIn: 'root'
})
export class InformationMetadataService {

    private api: BaseApi;

    public constructor(
        base: BaseService
    ) {
        this.api = base.setApi('gateway', 'core/informationMetadata');
    }

    public getPassengerInformation(): Promise<Array<InfoMetadata>> {
        return lastValueFrom(this.api.get('getPassengerInformation'));
    }
}