import { Price } from '../pricing/price';
import { TripKey } from '../trip/trip-key';
import { BookingCoupon } from './booking-coupon';

export class BookingTicket {
    tripKey: TripKey;
    ticketPrice: Price;
    coupons: BookingCoupon[] = [];
    passengerKey: string;
    idFareFamily: number;
    fareFamilyName: string;
    promoCode: string;
    boardingPoint: string;
    disembarkationPoint: string;
    ticketKey: string;
    promoCodeError: boolean;
    promoCodeErrorMessage: string;
    classOfServiceName: string;
}