import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';
import { ConfigService } from './config.service';

export type Domains = 'schedule' | 'sale' | 'core' | 'pricing' | 'customer' | 'gateway';

@Injectable()
export class BaseService {

    public constructor(
        public httpClient: HttpClient,
        public configService: ConfigService,
        private authService: AuthService
    ) {
    }

    public resolveUrl(domain: Domains): string {
        let baseUrl = '';
        switch (domain) {
            case 'schedule':
                baseUrl = this.configService.config.scheduleApiUrl;
                break;
            case 'sale':
                baseUrl = this.configService.config.saleApiUrl;
                break;
            case 'core':
                baseUrl = this.configService.config.coreApiUrl;
                break;
            case 'pricing':
                baseUrl = this.configService.config.pricingApiUrl;
                break;
            case 'customer':
                baseUrl = this.configService.config.customerApiUrl;
                break;
            case 'gateway':
                baseUrl = this.configService.config.gatewayApiUrl;
                break;
            default:
                throw 'Invalid domain: ' + domain;
        }
        return baseUrl;
    }

    public setApi(domain: Domains, controllerName: string, idUser?: number): BaseApi {
        return new BaseApi(this.resolveUrl(domain), controllerName, this.httpClient, this.configService, idUser, this.authService, domain == 'gateway');
    }

}
