export * from './clear-temp-trip.service';
export * from './internal-announcer.service';
export * from './seat-booking.service';
export * from './daily-schedule-route.service';
export * from './seat-map-pricing.service';
export * from './vehicle-configuration.service';
// export * from './outlet-booking.service';
export * from './misc-stock-booking.service';
export * from './outlet.service';
export * from './misc-extra-type.service';
export * from './form-of-payment.service';
export * from './sale-event.service';

import { ClearTempTripService } from './clear-temp-trip.service';
import { InternalAnnouncerService } from './internal-announcer.service';
import { SeatBookingService } from './seat-booking.service';
import { DailyScheduleRouteService } from './daily-schedule-route.service';
import { SeatMapPricingService } from './seat-map-pricing.service';
import { VehicleConfigurationService } from './vehicle-configuration.service';
// import { SaleService } from './outlet-booking.service';
import { MiscStockBookingService } from './misc-stock-booking.service';
import { OutletService } from './outlet.service';
import { MiscExtraTypeService } from './misc-extra-type.service';
import { FormOfPaymentService } from './form-of-payment.service';
import { SaleEventService } from './sale-event.service';
import { PassengerConfigurationService } from './passenger-configuration-service';
import { InformationMetadataService } from './information-metadata.service';
export const ALL_BOOKING_PROCESS_SERVICES = [
    ClearTempTripService,
    InternalAnnouncerService,
    SeatBookingService,
    DailyScheduleRouteService,
    SeatMapPricingService,
    VehicleConfigurationService,
    // SaleService,
    MiscStockBookingService,
    OutletService,
    MiscExtraTypeService,
    FormOfPaymentService,
    SaleEventService,
    PassengerConfigurationService,
    InformationMetadataService
]