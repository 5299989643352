import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { StorageService } from '../services';

@Injectable()
export class BrowserStateInterceptor implements HttpInterceptor {

    constructor(
        private storage: StorageService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {        
        const loggedCustomer = this.storage.getLoggedCustomer();
        if (!loggedCustomer || !loggedCustomer.idCustomer || loggedCustomer.idCustomer == 0) {
            this.storage.setLoggedCustomer(null);
            return next.handle(req);
        }        

        // Clone the request to add the new header.
        const authReq = req.clone({
            headers: req.headers.set('customerIdentifier', loggedCustomer.identifier)
            .set('idCustomer', loggedCustomer.idCustomer.toString()),        
            method: req.method
        });        

        // Pass on the cloned request instead of the original request.
        return next.handle(authReq).pipe(timeout(300000));
    }
}