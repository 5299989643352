import { BookingTicket, TripSearchData } from "../../models";

export class PurchaseEvent {
    event: string = 'purchase';
    transactionId: string = '';
    transactionTotal: number;
    transactionTax: number;
    transactionCoupon: string;
    taxes: PurchaseTaxes;
    transactionProducts: TransactionsProduct[] = [];

    constructor(
        tripSearchData: TripSearchData,
        tickets: BookingTicket[],
        recordLocator: string) {
        this.transactionTotal = 0;
        this.transactionTax = 0;
        this.transactionCoupon = tripSearchData.promoCode ? tripSearchData.promoCode : '';
        this.transactionId = recordLocator;
        this.taxes = new PurchaseTaxes();

        const serviceClasses = [];

        for (let ticket of tickets) {
            for (let coupon of ticket.coupons) {
                if (coupon.price != undefined && coupon.price.boardingFee != undefined && coupon.price.boardingFee.currencyValue != null) {
                    this.taxes.boarding += coupon.price.boardingFee.currencyValue;
                }

                if (coupon.price != undefined && coupon.price.mandatoryInsurance != undefined && coupon.price.mandatoryInsurance.currencyValue != null) {
                    this.taxes.security += coupon.price.mandatoryInsurance.currencyValue;
                }

                if (coupon.price != undefined && coupon.price.toll != undefined && coupon.price.toll.currencyValue != null) {
                    this.taxes.toll += coupon.price.toll.currencyValue;
                }

                if (coupon.price != undefined && coupon.price.convenienceFee != undefined && coupon.price.convenienceFee.currencyValue != null) {
                    this.taxes.convenience += coupon.price.convenienceFee.currencyValue;
                }

                if (coupon.price != undefined && coupon.price.total != undefined && coupon.price.total.currencyValue != null) {
                    this.transactionTotal += coupon.price.total.currencyValue;
                }
            }

            if (serviceClasses.indexOf(ticket.classOfServiceName) === -1) {
                serviceClasses.push(ticket.classOfServiceName);
            }
        }

        for (let key in this.taxes) {
            this.transactionTax += this.taxes[key];
        }

        const category = tripSearchData.hasReturnTrip ? 'Ida e Volta' : 'Ida';
        for (let serviceClass of serviceClasses) {
            let product = new TransactionsProduct();
            product.category = category;
            product.price = 0;
            product.name = serviceClass;
            const localTickets = tickets.filter(c => c.classOfServiceName === serviceClass);
            product.price = localTickets[0].coupons[0].price.fare.currencyValue;
            product.quantity = localTickets.length;
            this.transactionProducts.push(product);
        }

        this.transactionTotal = this.roundNumber(this.transactionTotal);
        this.transactionTax = this.roundNumber(this.transactionTax);
    }

    roundNumber = (value: number) : number => {
        if (!value) {
            return null;
        }

        return Math.round((value + Number.EPSILON) * 100) / 100;
    }
}

export class PurchaseTaxes {
    boarding: number;
    security: number;
    toll: number;
    convenience: number;

    constructor() {
        this.boarding = 0;
        this.security = 0;
        this.toll = 0;
        this.convenience = 0;
    }
}

export class TransactionsProduct {
    sku: string;
    name: string;
    price: number;
    quantity: number;
    category: string;

    constructor() {
        this.quantity = 0;
        this.price = 0;
    }
}