import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class DatePrice {
    date: NgbDate;
    price: number;

    constructor(
        date: NgbDate,
        price: number
    ) {
        this.date = date;
        this.price = price;
    }
}