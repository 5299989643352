import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseApi, BaseService } from 'src/app/main';
import { VehicleConfiguration } from '../models';

@Injectable({
    providedIn: 'root'
})
export class VehicleConfigurationService {

    private api: BaseApi;

    public constructor(
        base: BaseService
    ) {
        this.api =  base.setApi('gateway', 'schedule/vehicle');
    }

    public getVehicleConfiguration(id: number) : Observable<VehicleConfiguration> {
        const data = {
            id: id
        };

        return this.api.post<{ vehicleConfiguration: VehicleConfiguration}>('configuration', data).pipe(map(m => m.vehicleConfiguration))
    }

}