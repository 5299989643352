import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable()
export class SessionStorageHelper {
    isBrowser: boolean;
    constructor(
        @Inject(PLATFORM_ID) private platform: any
    ) {
        this.isBrowser = isPlatformBrowser(platform);
    }

    setItem = (key: string, value: any): void => {
        let jsonString = JSON.stringify(value, function (key, val) {
            return (typeof val === 'function') ? '' + val : val;
        });
        window.sessionStorage.setItem(key, jsonString);
    }

    private cloneProperties(source, dest): any {
        if (source !== null && dest !== null) {
            for (let property in source) {
                if (source.hasOwnProperty(property)) {
                    if (dest[property] && typeof (dest[property]) == "function")
                        continue;
                    dest[property] = source[property];
                }
            }
        }
        return dest;
    }

    getItem(key: string, objToGetMethodsFrom = null): any {
        const json = window.sessionStorage.getItem(key);
        let storedObj = (json !== null) ? JSON.parse(json) : null;

        if (objToGetMethodsFrom !== null) {
            return this.cloneProperties(storedObj, objToGetMethodsFrom);
        }

        return storedObj;
    }

    removeItem = (key: string): void => {
        window.sessionStorage.removeItem(key);
    }
}