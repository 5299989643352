import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SeatRequestConfirmation } from '../models';
import { BaseApi, BaseService, EmptySerializer, ICompanyConfig } from 'src/app/main';
import { SeatRequestConfirmationSerializer } from '../serializers';

@Injectable({
    providedIn: 'root'
})
export class SeatBookingService {

    private api: BaseApi;
    private config: ICompanyConfig;
    
    public constructor(
        private base: BaseService
    ) {
        this.api =  base.setApi('gateway', 'sale');
        this.config = this.base.configService.company;
    }

    public requestSeatBooking(
        idDailyScheduleRoute: number,
        idPartnerDailyScheduleRoute: number,
        hasReturn: boolean,
        isReturn: boolean,
        idSeatMapCell: number,
        seatIdentifier: string,
        promoCode: string,
        idTrip: number,
        tripType: number,
        idFareFamily: number,
        idBookingCouponRevalidation: number | null = null,
        discountType: number | null = null,
        idPassengerType: number = this.config.normalPassengerType,
        idPassengerTypeConfiguration: number = this.config.normalPassengerTypeConfiguration,
    ): Observable<SeatRequestConfirmation> {
        const data = {
            hasPricing: false,
            isReturn:isReturn,
            hasReturn: hasReturn,
            idDailyScheduleRoute: idDailyScheduleRoute,
            idPartnerDailyScheduleRoute: idPartnerDailyScheduleRoute,
            idPassengerType: idPassengerType,
            idPassengerTypeConfiguration: idPassengerTypeConfiguration,
            idSeatMapCell: idSeatMapCell,
            seatIdentifier: seatIdentifier,
            promoCode: promoCode,
            idTrip: idTrip,
            tripType: tripType,
            idFareFamily: idFareFamily,
            idBookingCouponRevalidation: idBookingCouponRevalidation,
            discountType: discountType
        };

        return this.api.post<SeatRequestConfirmation>('seat/prebooking', data);
    }

    public requestSeatBookingWithFareFamily(
        idDailyScheduleRoute: number,
        idPartnerDailyScheduleRoute: number,
        hasReturn: boolean,
        isReturn: boolean,
        idSeatMapCell: number,
        seatIdentifier: string,
        promoCode: string,
        idFareFamily: number
    ): Observable<SeatRequestConfirmation> {
        const data = {
            hasPricing: false,
            hasReturn: hasReturn,
            isReturn:isReturn,
            idDailyScheduleRoute: idDailyScheduleRoute,
            idPartnerDailyScheduleRoute: idPartnerDailyScheduleRoute,
            idPassengerType: this.config.normalPassengerType,
            idPassengerTypeConfiguration: this.config.normalPassengerTypeConfiguration,
            idSeatMapCell: idSeatMapCell,
            seatIdentifier: seatIdentifier,
            promoCode: promoCode,
            idFareFamily: idFareFamily
        };

        return this.api.post('RequestSeatAndStockBookingLowestPriceNoAuth', data, new SeatRequestConfirmationSerializer())
    }

    public cancelSeatBooking(
        seatIdentifier: string,
        stockIdentifier: string,
        isExternalSale:boolean = false
    ): Observable<any> {
        const data = {
            seatTransactionGuid: seatIdentifier,
            stockTransactionGuid: stockIdentifier,
            isExternalSale: isExternalSale
        }
        return this.api.post('seat/cancel', data, new EmptySerializer());
    }
}