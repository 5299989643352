import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseApi, ICompanyConfig, BaseService } from 'src/app/main';
import { SeatMapCell } from '../models';
import { SeatMapCellSerializer } from '../serializers';

@Injectable({
    providedIn: 'root'
})
export class DailyScheduleRouteService {

    private api: BaseApi;
    private config: ICompanyConfig;

    public constructor(
        private base: BaseService
    ) {
        this.api = base.setApi('gateway', 'sale/seats');
        this.config = this.base.configService.company;
    }


    public getSeatMapCell(
        idDailyScheduleRoute: number,
        idPartnerDailyScheduleRoute: number,
        idSeatMapCellSelecteds: number[],
        idPassengerTypeConfiguration:number,
        idPassengerType:number,
        idPassengerClassificationList:number[]
    ): Observable<any> {

        const data = {
            idDailyScheduleRoute: idDailyScheduleRoute,
            idPartnerDailyScheduleRoute: idPartnerDailyScheduleRoute,
            idPassengerType: idPassengerType,
            idPassengerTypeConfiguration: idPassengerTypeConfiguration,
            idSeatMapCellSelecteds: idSeatMapCellSelecteds,
            idPassengerClassificationList: idPassengerClassificationList
        }
        return this.api.post('map', data);
    }

    public getSeatMapCellPromise(
        idDailyScheduleRoute: number,
        idPartnerDailyScheduleRoute: number,
        idSeatMapCellSelecteds: number[]): Promise<SeatMapCell[]> {
        const data = {
            IdDailyScheduleRoute: idDailyScheduleRoute,
            IdPartnerDailyScheduleRoute: idPartnerDailyScheduleRoute,
            IdPassengerType: this.config.normalPassengerType,
            IdPassengerTypeConfiguration: this.config.normalPassengerTypeConfiguration,
            IdSeatMapCellSelecteds: idSeatMapCellSelecteds
        }
        return this.api.postAsCollection('GetSeatMapNoAuth', data, new SeatMapCellSerializer()).toPromise();

    }
}
