import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICompanyConfig, Dropdown, ScheduleRanking, LocationInfo, ScheduleLocation } from '../models';
import { DropdownSerializer, EmptySerializer, ScheduleLocationSerializer } from '../serializers';
import { BaseApi } from './base.api';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    private api: BaseApi;
    private config: ICompanyConfig;

    public constructor(
        base: BaseService
    ) {
        this.api = base.setApi('gateway', 'schedule/location');
        this.config = base.configService.company;
    }

    public getLocationsRanking(locationName: string): Observable<ScheduleRanking[]> {
        const data = {
            LocationName: locationName,
            Companies: this.config.locationsCompanies.split(",").map(m => parseInt(m.trim()))
        };

        return this.api.postAsCollection('GetLocationsRanking', data, new ScheduleLocationSerializer());
    }

    public getPossibleLocationsRanking(): Observable<ScheduleRanking[]> {
        const data = {
            Companies: this.config.locationsCompanies.split(",").map(m => parseInt(m.trim()))
        };

        return this.api.postAsCollection('GetPossibleLocationsRanking', data, new ScheduleLocationSerializer());
    }

    public getDepartureLocationsRankingByAutocompleteNoAuth(searchTerm: string, mobile: boolean = false): Observable<ScheduleRanking[]> {
        const data = {
            searchTerm: searchTerm,
            companies: this.config.locationsCompanies.split(",").map(m => parseInt(m.trim()))
            , range: this.config.idTenant === 2 && mobile ? null : 10
        };

        return this.api.post<{locations:ScheduleRanking[]}>('departure', data).pipe(map((data) => data.locations));
    }

    public getArrivalLocationsRankingByAutocompleteNoAuth(searchTerm: string, departureLocationName: string, mobile: boolean = false): Observable<ScheduleRanking[]> {
        const data = {
            SearchTerm: searchTerm,
            companies: this.config.locationsCompanies.split(",").map(m => parseInt(m.trim())),
            DepartureLocationName: departureLocationName,
            Range: this.config.idTenant === 2 && mobile ? null : 10
        };

        return this.api.post<{locations:ScheduleRanking[]}>('arrival', data).pipe(map((data) => data.locations));
    }

    public getLocationSchedules(locationName: string): Observable<LocationInfo[]> {
        const data = {
            locationName: locationName,
            companies: this.config.locationsCompanies
        };

       return  this.api.post<LocationInfo[] >('GetLocationSchedulesList', data);
    }

    public getLocationAvailables(): Observable<string[]> {
        const data = {
            locationName: '',
            companies: this.config.locationsCompanies
        };

        return this.api.post<string[]>('GetLocationsSchedulesAvailables', data);
    }

    getAvailableDates = (
        departureLocationName: string,
        arrivalLocationName: string,
        departureDate: Date,
        isOutlet: boolean = false,
        seats: number = 1,
        returnDate: Date = null,
        idPassengerType: number = 0,
        idBookingCouponRevalidation: number = null,
        showUnavailablePricingTrips: boolean = false,
        searchType: number = 2): Observable<Date[]> => {

        const data = {
            Companies: this.config.locationsCompanies,
            DepartureDate: departureDate,
            DepartureLocationName: departureLocationName,
            ArrivalLocationName: arrivalLocationName,
            IsOutlet: isOutlet,
            Seats: seats,
            ReturnDate: returnDate,
            IdPassengerType: idPassengerType,
            ShowUnavailablePricingTrips: showUnavailablePricingTrips,
            IdBookingCouponRevalidation: idBookingCouponRevalidation,
            SearchType: searchType
        };

        return this.api.postAsCollection('GetAvaliableDatesFromDailySchedule', data, new EmptySerializer());
    }
}
