import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApi, BaseService, EmptySerializer } from 'src/app/main';
import { DataConfiguration } from 'src/app/main/models/trip/data-configuration';

@Injectable({
    providedIn: 'root'
})
export class DataConfigurationService {

    private api: BaseApi;

    constructor(
        base: BaseService
    ) {
        this.api = base.setApi('gateway', 'core');///CustomerAuth
    }

    public getGratuityDiscountTypes(): Observable<{ GratuityDiscountTypes: string }> {
        return this.api.get('DataConfiguration/GetByKey?key=GratuityDiscountTypes', new EmptySerializer());
    }
}