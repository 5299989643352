import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/main';
import { SeatBookingService } from './seat-booking.service';

@Injectable({
    providedIn: 'root'
})
export class ClearTempTripService {
    constructor(private storage: StorageService,
        private seatBookingService: SeatBookingService) { }

    clearTempTrips(isReturnTrip: boolean): Observable<boolean> | Promise<boolean> | boolean {
        let currentTempTrip = this.storage.getCurrentTempTrip(isReturnTrip);
        if (currentTempTrip) {
            let getIsExternalSale : boolean = this.storage.getIsExternalSale();
            let isExternal = typeof(getIsExternalSale) != "undefined" ? getIsExternalSale : false;
            let coupons = this.storage.getCurrentTempTripSeats(currentTempTrip, isReturnTrip);
            if (coupons != null) {
                for (let localCoupon of coupons) {
                    this.seatBookingService.cancelSeatBooking(
                        localCoupon.seatBookingIdentify,
                        localCoupon.stockBookingIdentify,
                        isExternal
                    ).subscribe(c => {
                    });
                }
            }
            this.storage.setIsExternalSale(false);
            this.storage.removeCurrentTempTripSeats(isReturnTrip, currentTempTrip);
            this.storage.removeCurrentTempTrip(isReturnTrip);
            return true;
        }
        return false;
    }
}