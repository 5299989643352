import { ICompanyConfig } from "../models/config/company-config";
import { Serializer } from "../models/helpers/serializer";
import { TripPrice } from "../models/pricing/trip-prices";

export class TripPriceSerializer implements Serializer {

    public constructor(
        private config: ICompanyConfig
    ) {
    }

    public fromJson(json: any): any {
    }

    public fromJsonCollection(json: any): any[] {
        const result: TripPrice[] = [];
        for (let key in json) {
            let details = json[key]
                .filter(c => c.IdPassengerType === this.config.normalPassengerType
                    && c.IdPassengerTypeConfiguration === this.config.normalPassengerTypeConfiguration
                    && c.AvailableSeats > 0);

            if (details.length == 0) {
                details = json[key]
                    .filter(c => c.IdPassengerType === this.config.openIdPassengerType && c.RoutePrices[0].tripKey.TripType === 3)

                if (details.length == 0) {
                    continue;
                }
            }

            for (let item of details) {
                const tripPrice: TripPrice = {
                    idFareFamily: Number(key),
                    price: Object.assign({}, item.RoutePrices[0].Price),
                    priceDetail: Object.assign({}, item.RoutePrices[0].PriceDetail),
                    allowSeatSelection: item.AllowSeatSelection,
                    availableSeats: item.AvailableSeats
                };

                if (item.RoutePrices[0].tripKey.TripType === 3) {
                    tripPrice.availableSeats = 1;
                }

                result.push(tripPrice);
            }
        }

        return result;
    }
} 