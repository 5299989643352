import { Injectable } from '@angular/core';
import moment from 'moment';
import { map, Observable, of } from 'rxjs';
import { ICompanyConfig, MinimumPriceCache, OutletTripsSearch, Trip, TripMinimumPrice, TripPrice, TripSearch } from '../models';
import {
    OutletSearchSerializer, TripPriceSerializer,
    TripSearchToConfirmSerializer
} from '../serializers';
import { BaseApi } from './base.api';
import { BaseService } from './base.service';
import { GetTripsPassengerParams } from 'src/app/booking-process/models/booking-process/passenger-param';

@Injectable({
    providedIn: 'root'
})
export class TripService {

    private api: BaseApi;
    private config: ICompanyConfig;

    public constructor(
        base: BaseService
    ) {
        this.api = base.setApi('gateway', 'sale');
        this.config = base.configService.company;
    }

    public getTrips(
        departureLocationName: string,
        arrivalLocationName: string,
        isReturn: boolean,
        departureDate: Date,
        promoCode: string,
        seats: number,
        idBookingCouponRevalidation: number,
        returnDate?: Date,
        searchType: number = 2,
        discountType: number | null = null,
        isOpen: boolean = false,
        idPassengerType: number = undefined,        
        passengers: GetTripsPassengerParams = undefined

    ): Observable<TripSearch> {
        var data = {
            departureLocationName: departureLocationName,
            arrivalLocationName: arrivalLocationName,
            isReturn: isReturn,
            departureDate: this.adjustDate(departureDate),
            promoCode: promoCode,
            seats: seats,
            idBookingCouponRevalidation: idBookingCouponRevalidation,
            returnDate: this.adjustDate(returnDate),
            searchType: searchType,
            discountType: discountType,
            isOpen: isOpen,
            idPassengerType: idPassengerType,
            passengers: passengers
        }
        return this.api.post('trips', data).pipe(map((data: any) => {
            if (data && data.trips) {
                const newTrips: Trip[] = data.trips.map(m => Object.assign(new Trip(), m));
                return {
                    searchSettings: data.searchSettings,
                    currentPage: data.currentPage,
                    pageCount: data.pageCount,
                    trips: newTrips?.map(m => {
                        var dep = m.departureDateTime as any as string;
                        var depDate = new Date(dep);
                        m.departureDateTime = {
                            dateTimeUTC: dep,
                            day: depDate.getDate(),
                            month: depDate.getMonth() + 1,
                            hour: depDate.getHours(),
                            minute: depDate.getMinutes(),
                            year: depDate.getFullYear(),
                            offset: m.departureDateTimeOffset,
                            second: depDate.getSeconds()
                        }

                        var arr = m.arrivalDateTime as any as string;
                        var arrDate = new Date(arr);
                        m.arrivalDateTime = {
                            dateTimeUTC: arr,
                            day: arrDate.getDate(),
                            month: arrDate.getMonth() + 1,
                            hour: arrDate.getHours(),
                            minute: arrDate.getMinutes(),
                            year: arrDate.getFullYear(),
                            offset: m.arrivalDateTimeOffset,
                            second: arrDate.getSeconds()
                        }

                        return m;
                    }) || []
                } as TripSearch;
            }

            return {
                searchSettings: data.searchSettings,
                currentPage: data.currentPage,
                pageCount: data.pageCount,
                trips: []
            }

        }


        ));


    }

    public getOutletTrips(
        departureLocationName: string,
        arrivalLocationName: string,
        isReturn: boolean,
        departureDate: Date,
        seats: number,
        returnDate?: Date
    ): Observable<OutletTripsSearch> {
        const data = {
            DepartureLocationName: departureLocationName,
            ArrivalLocationName: arrivalLocationName,
            IdPassengerType: this.config.normalPassengerType,
            DepartureDate: {
                Day: departureDate.getDate(),
                Hour: 0,
                Millisecond: 0,
                Minute: 0,
                Month: departureDate.getMonth() + 1,
                Second: 0,
                Year: departureDate.getFullYear()
            },
            SearchType: 2,
            IdBookingCouponRevalidation: null,
            IdContract: "-1",
            IdCurrency: 1,
            IdDepartureLocation: null,
            IdPointOfSale: 0,
            IdPromoCode: "-1",
            IdStaffTravelCategory: 0,
            IdStaffTravelType: 0,
            IsDeleted: false,
            IsDepartureDateOpen: null,
            IsOpen: false,
            IsReadOnly: false,
            IsReturn: isReturn,
            IsWithoutPrint: false,
            ReturnDate: {
                Day: returnDate !== null && returnDate !== undefined ? returnDate.getDate() : 0,
                Hour: 0,
                Millisecond: 0,
                Minute: 0,
                Month: returnDate !== null && returnDate !== undefined ? returnDate.getMonth() + 1 : 0,
                Second: 0,
                Year: returnDate !== null && returnDate !== undefined ? returnDate.getFullYear() : 0
            },
            SearchPage: {
                PageSize: -1,
                CurrentPage: 1
            },
            Seats: seats
        };

        return this.api.post('GetOutletTripsNoAuth', data, new OutletSearchSerializer());
    }

    public getTripPrices(
        departureDate: Date,
        returnDate: Date,
        idArrivalLocation: number,
        IdDepartureLocation: number,
        idTrip: number,
        isReturn: boolean,
        hasPricing: boolean,
        tripType: number,
        isOpen: boolean = false,
        searchType: number,
        seats: number
    ): Observable<TripPrice[]> {
        const data = {
            BookingCouponToExchangeList: [],
            BookingCouponToRevalidateList: [],
            DepartureDate: isOpen ? null : {
                Day: departureDate.getDate(),
                Hour: 0,
                Millisecond: 0,
                Minute: 0,
                Month: departureDate.getMonth() + 1,
                Second: 0,
                Year: departureDate.getFullYear()
            },
            HasPricing: hasPricing,
            IdArrivalLocation: idArrivalLocation,
            IdContract: '-1',
            IdCurrency: 1,
            IdDailyScheduleRoute: '',
            IdDepartureLocation: IdDepartureLocation,
            IdPaymentBookingMiscCoupon: null,
            IdPromoCode: "-1",
            PromoCode: isOpen && this.config.offerOpen ? this.config.openPromoCode : "-1",
            IdStaffTravelType: 0,
            IsReturn: isReturn,
            ReturnDate: {
                Day: returnDate !== null && returnDate !== undefined ? returnDate.getDate() : 0,
                Hour: 0,
                Millisecond: 0,
                Minute: 0,
                Month: returnDate !== null && returnDate !== undefined ? returnDate.getMonth() + 1 : 0,
                Second: 0,
                Year: returnDate !== null && returnDate !== undefined ? returnDate.getFullYear() : 0
            },
            SearchType: searchType,
            TripKeys: [
                {
                    IdArrivalLocation: idArrivalLocation,
                    IdDepartureLocation: IdDepartureLocation,
                    idTrip: idTrip,
                    TripType: tripType
                }
            ],
            WithInsurance: true,
            IdPassengerType: isOpen || this.config.offerOpen ? this.config.openIdPassengerType : "-1",
            Seats: seats
        };
        return this.api.postAsCollection('GetTripPricesNoAuth', data, new TripPriceSerializer(this.config));
    }

    public getMinimumPrices(
        departureDate: Date,
        returnDate: Date,
        hasPricing: boolean,
        arrivalLocationName: string,
        departureLocationName: string,
        isReturn: boolean,
        promoCode: string = '',
        idBookingCouponRevalidation: number | null = null,
        discountType: number | null = null,
        idPassengerType: number,
        seats: number,
        passengers: GetTripsPassengerParams
    ): Observable<TripMinimumPrice[]> {
        var data = {
            departureLocationName: departureLocationName,
            arrivalLocationName: arrivalLocationName,
            isReturn: isReturn,
            departureDate: this.adjustDate(departureDate),
            promoCode: promoCode,
            seats: seats,
            idBookingCouponRevalidation: idBookingCouponRevalidation,
            returnDate: this.adjustDate(returnDate),
            hasPricing: hasPricing,
            discountType: discountType,
            idPassengerType: idPassengerType,
            passengers: passengers
        }

        return this.api.post('trips/prices/day', data).pipe(map((m) => {
            const result: TripMinimumPrice[] = []
            for (let item of m.lowestPriceByDays) {
                let date = moment(item.date).toDate();
                const minTripPrice: TripMinimumPrice = {
                    day: date.getDate() + 1, // Bug TimeZone (André - Astolfo)
                    month: date.getMonth() + 1,
                    year: date.getFullYear(),
                    price: item.price
                };
                minTripPrice.when = new Date(minTripPrice.year, minTripPrice.month - 1, minTripPrice.day),
                    minTripPrice.unavailable = item.price === null ? true : item.unavailable;
                result.push(minTripPrice)
            }
            return result;
        }
        ));
    }

    public getTripMinimumPricesRange(idDepartureLocation: number, idArrivalLocation: number, tripDate: Date): Observable<MinimumPriceCache[]> {
        const data = {
            IdDepartureLocation: idDepartureLocation,
            IdArrivalLocation: idArrivalLocation,
            StartingDate: tripDate
        };

        return this.api.post<MinimumPriceCache[]>('GetCachedMinimumPrices', data);
    }

    public getCachedMinimumPricesByLocationsName(departureLocationName: string, arrivalLocationName: string, tripDate: Date): Observable<MinimumPriceCache[]> {
        const data = {
            departureLocationName: departureLocationName,
            arrivalLocationName: arrivalLocationName,
            startingDate: tripDate
        };
        return this.api.post<MinimumPriceCache[]>('trips/prices/minimumPricesByLocationsName', data)
    }

    public getTripsToConfirm(
        departureLocationName: string,
        arrivalLocationName: string,
        departureDate: Date,
        searchType: number = 6,
        idBookingCoupon: number
    ): Observable<TripSearch> {
        const data = {
            DepartureLocationName: departureLocationName,
            ArrivalLocationName: arrivalLocationName,
            DepartureDate: {
                Day: departureDate.getDate(),
                Hour: 0,
                Millisecond: 0,
                Minute: 0,
                Month: departureDate.getMonth() + 1,
                Second: 0,
                Year: departureDate.getFullYear()
            },
            SearchType: searchType,
            IdBookingCoupon: idBookingCoupon
        };

        return this.api.post('GetTripsToConfirmNoAuth', data, new TripSearchToConfirmSerializer());
    }

    private adjustDate(dateWithTimezoneOffset: Date): Date {
        if (dateWithTimezoneOffset == null || dateWithTimezoneOffset == undefined) {
            return undefined;
        }
        var userTimezoneOffset = dateWithTimezoneOffset.getTimezoneOffset() * 60000;
        return new Date(dateWithTimezoneOffset.getTime() - userTimezoneOffset);
    }
}
