import { DateHelper } from '../../helpers';
import { Trip } from '../../models';
import { Travel } from '../models/travel';

export class InitiateCheckoutEvent {
    event: string = 'initiateCheckout';
    travels: Travel[] = [];
    ecommerce: any;

    constructor(
        trips: Trip[],
        cupon: string
    ) {
        for (let trip of trips) {
            const travel: Travel = {
                originBusStation: trip.departureLocationName,
                departingDepartureDate: trip.departureDateTime ? DateHelper.convertOreonsDateTimeToISO(trip.departureDateTime) : '',
                destinationBusStation: trip.arrivalLocationName,
                returningDepartureDate: trip.arrivalDateTime ? DateHelper.convertOreonsDateTimeToISO(trip.arrivalDateTime) : '',
                cuponCode: cupon ? cupon : '',
                travelClass: trip.classOfServiceName
            };
            this.travels.push(travel);
            this.ecommerce = {
                checkout: {
                    actionField: {
                        step: 4,
                    }
                }
            }
        }
    }
}